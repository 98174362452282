export const formatDateDay = (dateString: string) => {
    if (dateString) {
        // Date 객체 생성
        const date = new Date(dateString);

        // 각 시간 구성 요소를 추출
        const year = date.getUTCFullYear(); // 끝의 두 글자만 추출하여 2자리 연도로 만듦
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더해줌
        const day = date.getUTCDate().toString().padStart(2, "0"); // 일자
        const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
        const weekday = weekdays[date.getUTCDay()]; // 요일은 getDay()로 얻은 숫자를 한글 요일로 변환

        // 형식에 맞춰 문자열을 반환
        return `${year}-${month}-${day} (${weekday})`;
    } else {
        return null;
    }
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
};

export const formatDateToUS = (dateString: string) => {
    if (dateString) {
        // Date 객체 생성
        const date = new Date(dateString);

        // 각 시간 구성 요소를 추출
        const year = date.getUTCFullYear(); // 네 자리 연도
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // 월
        const day = date.getUTCDate().toString().padStart(2, "0"); // 일자
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const weekday = weekdays[date.getUTCDay()]; // 요일을 영어로

        // 형식에 맞춰 문자열을 반환
        return `${year}-${month}-${day} (${weekday})`;
    } else {
        return null;
    }
};

export const formatEndTime = (time: string) => {
    if (time) {
        const [hours, minutes] = time.split(":");
        return `${hours}:${minutes}`;
    } else {
        return null;
    }
};

export const removeBulletAndTrim = (text: string): string => {
    // '•' 문자를 제거하고 양쪽의 공백을 제거합니다.
    return text.replace(/•/g, "").trim();
};

export const compareYear = (date: string | null, compare: string | null): string | null => {
    const year1 = date!.split("-");
    const year2 = compare!.split("-");
    // console.log(year1, "||", year2);
    if (year1[0] === year2[0]) {
        return `${year2[1]}-${year2[2]}`;
    } else {
        return compare;
    }
};

// export const formatFollowersCount = (followers: number) => {
//     if (followers < 10000) {
//         return `${followers}`; // 10,000 미만이면 그대로 표시
//     } else {
//         return `${Math.floor(followers / 10000)}만`; // 10,000 이상이면 "만" 단위로 표시
//     }
// };
export const formatFollowersCount = (followers: number | string, language: string) => {
    console.log(followers);
    if (typeof followers === "string") {
        return followers;
    }
    if (followers == null) {
        return "0"; // followers가 null 또는 undefined일 경우 "0" 반환
    }
    if (followers === 0) {
        return "0";
    }
    //console.log(followers);
    if (followers < 10000) {
        // 10,000 미만일 때는 쉼표가 포함된 그대로 숫자 표시
        return followers.toLocaleString();
    } else {
        // 10,000 이상일 때는 "만" 단위로 변환하고 쉼표 포함
        const formattedNumber = Math.floor(followers / 10000).toLocaleString(); // 정수로 변환 후 쉼표 적용
        if (language === "en") {
            return `${formattedNumber}M+`;
        } else {
            return `${formattedNumber}만+`;
        }
    }
};
