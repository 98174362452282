import styled from "styled-components";
import charFinger from "../../assets/images/program/char-finger.svg";
import Navibar from "../../components/navigation/Navibar";
import Footer from "../../components/footer/Footer";
import Triangle from "../../assets/images/program/img-triangle.svg";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowGreen from "../../assets/images/main/button-arrow-green.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import dateEn from "../../assets/images/program/img-date-en.svg";
import dateKo from "../../assets/images/program/img-date-ko.svg";
import locationEn from "../../assets/images/program/img-location-en.svg";
import locationKo from "../../assets/images/program/img-location-ko.svg";
import collaEn from "../../assets/images/program/img-collaboration-en.svg";
import collaKo from "../../assets/images/program/img-collaboration-ko.svg";
import descEn from "../../assets/images/program/img-description-en.svg";
import descKo from "../../assets/images/program/img-description-ko.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { compareYear, formatDateDay, formatDateToUS, formatEndTime } from "../../common/util-function";
import { useInView } from "react-intersection-observer";
import { applyTypography } from "../../styles/mixins";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Titles from "../../components/Title";
import Dividers from "../../components/Dividers";

const ProgramDetail = () => {
    const [titleKo, setTitleKo] = useState("");
    const [contentKo, setContentKo] = useState("");
    const [placeKo, setPlaceKo] = useState("");
    const [datesKo, setDatesKo] = useState("");
    const [datesEn, setDatesEn] = useState("");
    const [dateTimes, setDateTimes] = useState([
        {
            date: "",
            endTime: "",
            startTime: "",
        },
    ]); // Store dateTimes array

    const [endDateKo, setEndDateKo] = useState("");
    const [endDateEn, setEndDateEn] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [cooperateKo, setCooperateKo] = useState("");
    const [previewUrl, setPreviewUrl] = useState("");
    // const [attend, setAttend] = useState("");
    const [titleEn, setTitleEn] = useState("");
    const [contentEn, setContentEn] = useState("");
    const [placeEn, setPlaceEn] = useState("");
    const [cooperateEn, setCooperateEn] = useState("");
    const [linkKo, setLinkKo] = useState("");
    const [linkEn, setLinkEn] = useState("");
    const [category, setCategory] = useState("");
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    const { postId } = useParams();

    const navi = useNavigate();

    const goBack = () => {
        navi(-1);
    };

    const onLink = (link: string) => {
        window.open(link, "_blank");
    };

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPost = async () => {
            try {
                const response = await axios.get(`${APIURL}/program/category/${postId}`);
                const result = response.data.data;
                setTitleKo(result.titleKo);
                setContentKo(result.contentKo);
                setPlaceKo(result.placeKo);
                setDatesEn(result.date && formatDateToUS(result.date));
                setDatesKo(result.date && formatDateDay(result.date));
                setDateTimes(result.dateTimes || []); // Set dateTimes array

                setEndDateEn(result.endDate && formatDateToUS(result.endDate));
                setEndDateKo(result.endDate && formatDateDay(result.endDate));
                setStartTime(result.startTime && formatEndTime(result.startTime));
                setEndTime(result.endTime && formatEndTime(result.endTime));
                setCooperateKo(result.cooperateKo);
                setPreviewUrl(`${APIURL}/${result.imagePath}`);
                //setAttend(result.isAttend);
                setTitleEn(result.titleEn);
                setContentEn(result.contentEn);
                setPlaceEn(result.placeEn);
                setCooperateEn(result.cooperateEn);
                setLinkKo(result.linkKo);
                setLinkEn(result.linkEn);
                setCategory(result.category);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, []);

    const { ref: appRef, inView: appView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });

    return (
        <>
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <img src={charFinger} alt="도형" className="Triangle" />
                    <Title>{activeLanguage === "en" ? titleEn : titleKo}</Title>
                    <Divider />
                    <Contents>
                        <div className="right">
                            <div>
                                <img
                                    src={previewUrl}
                                    alt="팬 페스티벌"
                                    ref={appRef}
                                    className={
                                        appView ? "visible animate__animated animate__fadeInUp custom-fadeInUp" : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="left">
                            <div className="date">
                                <div className="first">{activeLanguage === "en" ? "Date" : "일정"}</div>
                                <p>
                                    {category != "wintafesta" && dateTimes && dateTimes.length > 0 ? (
                                        dateTimes.length === 1 ? (
                                            <>
                                                •{" "}
                                                {activeLanguage === "en" ? (
                                                    <>{formatDateToUS(dateTimes[0].date)}</>
                                                ) : (
                                                    <>{formatDateDay(dateTimes[0].date)}</>
                                                )}{" "}
                                                {dateTimes[0].startTime &&
                                                    `${formatEndTime(dateTimes[0].startTime)} ~ `}
                                                {dateTimes[0].endTime && formatEndTime(dateTimes[0].endTime)}
                                            </>
                                        ) : (
                                            <>
                                                {activeLanguage === "en" ? (
                                                    <>
                                                        • {formatDateToUS(dateTimes[0].date)}
                                                        {dateTimes[dateTimes.length - 1].date
                                                            ? ` ~ ${compareYear(
                                                                  formatDateToUS(dateTimes[0].date),
                                                                  formatDateToUS(dateTimes[dateTimes.length - 1].date)
                                                              )}`
                                                            : null}
                                                    </>
                                                ) : (
                                                    <>
                                                        • {formatDateDay(dateTimes[0].date)}
                                                        {dateTimes[dateTimes.length - 1].date
                                                            ? ` ~ ${compareYear(
                                                                  formatDateDay(dateTimes[0].date),
                                                                  formatDateDay(dateTimes[dateTimes.length - 1].date)
                                                              )}`
                                                            : null}
                                                    </>
                                                )}{" "}
                                                {dateTimes[0].startTime &&
                                                    `${formatEndTime(dateTimes[0].startTime)} ~ `}
                                                {dateTimes[dateTimes.length - 1].endTime &&
                                                    formatEndTime(dateTimes[dateTimes.length - 1].endTime)}
                                            </>
                                        )
                                    ) : (
                                        <>
                                            • {activeLanguage === "en" ? datesEn : datesKo}{" "}
                                            {activeLanguage === "en" && endDateEn
                                                ? `~ ${compareYear(datesEn, endDateEn)} `
                                                : null}
                                            {activeLanguage === "ko" && endDateKo
                                                ? `~ ${compareYear(datesKo, endDateKo)} `
                                                : null}
                                            {startTime && `${formatEndTime(startTime)} ~ `}
                                            {endTime && formatEndTime(endTime)}
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className="place">
                                <div className="first">{activeLanguage === "en" ? "Place" : "장소"}</div>
                                <p>• {activeLanguage === "en" ? placeEn : placeKo}</p>
                            </div>
                            <div className="desc">
                                <div className="first">{activeLanguage === "en" ? "Desc" : "내용"}</div>
                                <p>{activeLanguage === "en" ? contentEn : contentKo}</p>
                            </div>

                            {activeLanguage === "en" && cooperateEn ? (
                                <div className="coop">
                                    <div className="first partner">Partner</div>
                                    <p>{cooperateEn}</p>
                                </div>
                            ) : null}
                            {activeLanguage === "ko" && cooperateKo ? (
                                <div className="coop">
                                    <div className="first partner">파트너</div>
                                    <p>{cooperateKo}</p>
                                </div>
                            ) : null}

                            <div className="btnBox">
                                {activeLanguage === "en" && linkEn ? (
                                    <button className="btn" onClick={() => onLink(linkEn)}>
                                        {category === "wintafesta" ? "Go" : "Attend"}{" "}
                                        {/* <img src={buttonArrowGreen} alt="detail" /> */}
                                    </button>
                                ) : (
                                    ""
                                )}
                                {activeLanguage === "ko" && linkKo ? (
                                    <button className="btn" onClick={() => onLink(linkKo)}>
                                        {category === "wintafesta" ? "바로가기" : "신청하기"}{" "}
                                        {/* <img src={buttonArrowGreen} alt="detail" /> */}
                                    </button>
                                ) : (
                                    ""
                                )}
                                <button className="back" onClick={goBack}>
                                    {activeLanguage === "en" ? "Back" : "돌아가기"}{" "}
                                    {/* <img src={buttonArrow} alt="detail" /> */}
                                </button>
                            </div>
                        </div>
                    </Contents>
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default ProgramDetail;

const Wrap = styled(Wraps)``;

const PaddingWrap = styled(PaddingWraps)`
    .Triangle {
        display: none;
    }
    @media (min-width: 768px) {
        .Triangle {
            display: block;
            position: absolute;
            z-index: 100;
            top: 35px;
            right: 0px;
        }
    }

    @media (min-width: 1440px) {
        .Triangle {
            right: -90px;
        }
    }
`;

const Title = styled(Titles)``;

const Divider = styled(Dividers)``;

const Contents = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 1440px) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .right {
        width: 100%;

        @media (min-width: 1440px) {
            width: 49.5%;
            margin-bottom: 200px;
        }
    }
    .visible {
        visibility: visible;
    }

    .animate__animated {
        animation-delay: 0.1s;
    }

    .right img {
        width: 100%;
        height: 50vw;
        border: 4px solid #000;
        box-sizing: border-box;
        object-fit: cover;
        transition: transform 0.5s, filter 0.3s;
        will-change: transform, filter;

        @media (min-width: 768px) {
            height: 45vw;
            max-height: 500px;
        }
        @media (min-width: 1440px) {
            height: 100%;
            max-height: 100%;
        }
    }

    .custom-fadeInUp {
        animation: fadeInUp 1s ease forwards;
        transform: translateY(15%);
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .left {
        @media (min-width: 768px) {
            padding-right: 200px;
        }
        .date,
        .place,
        .desc,
        .coop {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-bottom: 44px;
            white-space: pre-wrap;
            word-wrap: break-word;
            word-break: break-word;
            .first {
                background-color: #fed800;
                display: flex;
                justify-content: center;
                align-items: center;
                /* height: 32px;
                width: 63px; */
                /* padding-top: 2px; */
                padding: 8px 12px;
                ${applyTypography("primary", 4)};

                @media (min-width: 768px) {
                    /* height: 38px;
                    width: 74px;  */
                }
            }
            /* .partner {
                width: 105px;
            } */

            p {
                margin-top: 16px;
                ${applyTypography("secondary", 0)};
            }
        }
        .coop {
            margin-bottom: 44px;
            @media (min-width: 768px) {
                margin-bottom: 72px;
            }
        }
        .date {
            margin-top: 28px;
            @media (min-width: 768px) {
                margin-top: 60px;
            }
            @media (min-width: 1440px) {
                margin-top: 0px;
            }
        }
    }
    .btnBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (min-width: 768px) {
            flex-direction: row;
        }

        .btn {
            width: 100%;
            background-color: transparent;
            border: 4px solid #000;
            height: 52px;
            ${applyTypography("secondary", 3)};
            cursor: pointer;
            @media (min-width: 768px) {
                width: 25%;
            }
            @media (min-width: 1440px) {
                width: 200px;
            }

            &:hover {
                color: #ffffff;
                background-color: #000;
                transition: 0.3s ease;
            }
        }
        .back {
            width: 100%;
            background-color: #000;
            border: 4px solid #000;
            height: 52px;
            ${applyTypography("secondary", 3)};
            color: #f9f8f8;
            cursor: pointer;
            margin-bottom: 100px;
            @media (min-width: 768px) {
                width: 25%;
                margin-bottom: 200px;
            }
            @media (min-width: 1440px) {
                width: 200px;
            }
            &:hover {
                background-color: #c30f23;
                border: 4px solid #c30f23;
                transition: 0.3s ease;
            }
        }
    }
`;
