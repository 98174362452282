import styled, { keyframes } from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { Helmet } from "react-helmet";
import { MainContent } from "../main/Main";
import { formatDate } from "../../common/util-function";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Titles from "../../components/Title";
import Dividers from "../../components/Dividers";
import { applyTypography } from "../../styles/mixins";
import open from "../../assets/images/info/open.svg";
import close from "../../assets/images/info/close.svg";
import q from "../../assets/images/info/Q.svg";

type Post = {
    qKo: string;
    aKo: string;
    qEn: string;
    aEn: string;
};
const FAQItemComponent = ({
    post,
    isOpen,
    onClick,
    language,
}: {
    post: Post;
    isOpen: boolean;
    onClick: () => void;
    language: string | null;
}) => {
    const [height, setHeight] = useState<number>(0);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            if (isOpen) {
                setHeight(contentRef.current.scrollHeight);
            } else {
                setHeight(0);
            }
        }
    }, [isOpen]);

    return (
        <>
            <FAQItem isOpen={isOpen} onClick={onClick}>
                <Question>
                    <QuestionText>
                        <img src={q} alt="q" />
                        {language === "en" ? post.qEn : post.qKo}
                    </QuestionText>
                    <ArrowIcon src={isOpen ? close : open} alt="arrow" />
                </Question>
            </FAQItem>
            <Answer
                style={{
                    height: `${height}px`,
                }}
            >
                <AnswerContent ref={contentRef}>{language === "en" ? post.aEn : post.aKo}</AnswerContent>
            </Answer>
        </>
    );
};

const Faq = () => {
    const [posts, setPosts] = useState<any[]>([]);
    const [attends, setAttends] = useState<any[]>([]);
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [typeKo, setTypeKo] = useState<any>({});
    const [typeEn, setTypeEn] = useState<any>({});
    // const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [openPostIndexes, setOpenPostIndexes] = useState<number[]>([]);
    const [openAttendIndexes, setOpenAttendIndexes] = useState<number[]>([]);

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        setPosts([
            {
                qKo: "서울콘은 인플루언서만 갈 수 있습니까?",
                aKo: "서울콘은 50개국, 3,000여 인플루언서 팀이 모이는 세계 최대 인플루언서 중심 박람회이지만, 시민들 누구나 방문해서 즐길 수 있습니다.",
                qEn: "Is SEOULCon only for influencers?",
                aEn: "SEOULCon is the world's largest influencer expo, bringing 3,000 influencer teams from 50 countries, but it's open to everyone.",
            },
            {
                qKo: "무료 행사인가요?",
                aKo: "LCK어워드, 서울콘X월드케이팝 페스티벌&카운트다운을 제외한 프로그램은 무료로 진행됩니다.",
                qEn: "Is it a free event?",
                aEn: "All programs are free of charge, except LCK Awards, MKSI 2024, SEOULCon X World K-Pop Festival & Countdown.",
            },
            {
                qKo: "주차는 지원하나요?",
                aKo: "주차의 경우 지원하지 않습니다. DDP 주변 교통 혼잡 관계로 대중교통 이용을 추천드립니다.",
                qEn: "Is parking available?",
                aEn: "Parking is not supported. Due to traffic congestion around DDP, we recommend using public transportation.",
            },
            {
                qKo: "행사장 내 반려동물 출입이 가능한가요?",
                aKo: "반려동물 동반 관람객은 목줄과 배변봉투를 반드시 지참하시고 처리 후 이동 바랍니다.",
                qEn: "Are pets allowed inside the event venue?",
                aEn: "Visitors with pets must bring a leash and a poop bag, and dispose of the waste before leaving.",
            },
            {
                qKo: "행사장 내부에서 음식물 섭취가 가능할까요?",
                aKo: "행사장 내부 외부음식 반입은 금하고 있습니다. 휴게공간에 있는 푸드트럭 등의 일부 공간에서 취식 가능합니다.",
                qEn: "Is it possible to eat food inside the event venue?",
                aEn: "Bringing outside food into the event venue is prohibited. Eating is permitted in certain areas, such as food trucks in the break area.",
            },
            {
                qKo: "서울콘에 필요한 준비물이 있습니까?",
                aKo: `먼저 서울콘 공식 홈페이지상 Attend 페이지에서 사전 신청을 완료합니다.
방문시 신분증(여권, 주민등록증, 운전면허증, 본인사진이 부착된 학생증, 외국인등록증 등), 개인 방한용품, 서울콘의 멋진 순간을 담을 수 있는 카메라 또는 휴대전화, 신나게 새해를 맞이할 마음가짐을 준비해주세요!`,
                qEn: "What do we need to attend SEOULCon?",
                aEn: `First, complete the pre-registration on the ‘Attend page’ of the SEOULCon official website.
When visiting, please prepare an ID card (passport, resident registration card, driver's license, student ID with photo, alien registration card, etc.), personal winter items, a camera or cell phone to capture the wonderful moments of SEOULCon, and an excited mindset to welcome the new year!`,
            },
            {
                qKo: "서울콘에 나이제한이 있습니까?",
                aKo: `다수 프로그램은 나이제한이 없습니다. 프로그램별 상이한 제한 기준으로 프로그램별 확인 바랍니다. ex) ‘서울콘 X 월드케이팝 페스티벌 & 카운트다운’은 만 19세 이상만 참가 가능
※ 나이제한이 있는 프로그램을 신청하신 경우, 증빙하실 수 있는 신분증 등 자료 지참 필수`,
                qEn: "Is there an age limit for SEOULCon?",
                aEn: `Most programs have no age restrictions. Please check each program for different restrictions. Ex) ‘SEOULCon X World K-pop Festival & Countdown’ is open to those aged 19 or older.
※ When participating in a program with age restrictions, you must bring any proof of age, such as an ID card.`,
            },
            {
                qKo: "재입장 관련 문의",
                aKo: `프로그램 재입장이 가능합니다.`,
                qEn: "Is re-entry possible?",
                aEn: "Re-entry is possible.",
            },
            {
                qKo: "현장대기 문의",
                aKo: `정해진 현장대기선 외의 DDP 내부 장소에서는 안전상 과도한 밀집을 해소하고 이동동선을 확보하기 위해 머무름 없이 이동해주시길 바랍니다.`,
                qEn: "About on-site waiting",
                aEn: `Inside DDP other than the designated waiting line, please move around without stopping in order to relieve excessive crowding and secure a safe movement path.`,
            },
            {
                qKo: "사진 촬영 관련 문의",
                aKo: `원칙적으로 사진 촬영은 가능합니다. 다만 사진 촬영을 금하는 일부 프로그램이 있어, 이 부분은 해당 프로그램 입장 시 자세히 안내드리도록 하겠습니다.`,
                qEn: "About taking photos?",
                aEn: `In principle, photography is allowed except some programs which prohibit it itself. So we will provide detailed information on this when you enter the each program.`,
            },
        ]);
        setAttends([
            {
                qKo: "[중복신청 문의] 서울콘에는 한 프로그램만 신청할 수 있습니까?",
                aKo: `중복 신청 가능합니다. 신청폼에서 프로그램 선택 시, 중복 선택하여 신청가능합니다. 프로그램별 일시와 장소 등을 사전에 확인하시길 바랍니다. 서울콘 공식 홈페이지(seoul-con.com) 내 프로그램 탭에서 상세한 내용을 확인하실 수 있습니다.`,
                qEn: "[About duplicate applications] Can I apply for only one program at SEOULCon?",
                aEn: `Multiple applications are possible. When selecting on the application form, you can apply for multiple programs. Please check the date and location of each program in advance. You can check detailed information on the program tab on the official SEOULCon website (seoul-con.com).`,
            },
            {
                qKo: "[중복신청 문의] 신청 시, 인플루언서이자 기업인 경우는 어떻게 하나요?",
                aKo: `중복 신청 가능합니다. 주체별 다양한 프로그램을 준비했습니다. (예) 비즈매칭 교류회, 인플루언서 파티 등) 프로그램 탭에서 상세한 내용을 확인하실 수 있습니다.`,
                qEn: "[About duplicate applications] What if I am both an influencer and a business company?",
                aEn: `Multiple applications are possible. We have prepared various programs for each subject. (Example) Business Matching Meeting, Influencer Party, etc.) You can check the details in the Program tab.`,
            },
            {
                qKo: "신청 후 확인/수정/취소는 어떻게 하나요?",
                aKo: `서울콘 공식 홈페이지> Attend 탭 > 캐치시큐 대시보드에 관리> 서비스 관리 > 수정하기/ 삭제 클릭`,
                qEn: "How to confirm/modify/cancel after applying?",
                aEn: `SEOULCon official homepage > Attend tab > Manage in Catch Security Dashboard > Manage Service > Click Edit/Delete`,
            },
            {
                qKo: "Attend(신청하기)에 아래 프로그램이 없습니다.",
                aKo: `신청 방식이 다른 프로그램 아래와 같이 안내 드립니다.
- LCK어워드 : 별도 티켓팅 진행
- 서울콘 X 월드케이팝 페스티벌 & 카운트다운 : 별도 티켓팅 진행
- 2024 SEOULCON APAN STAR AWARDS : 협력사에서 별도의 방식으로 방청권 배포
- 크리에이티브포스 어워즈 : 서울시와 서울경제진흥원이 육성하는 크리에이티브포스를 위한 행사로 그 외 참여 신청을 받지 않음`,
                qEn: "Some programs are not listed on ‘Attend’.",
                aEn: `Here’s the guidance for some programs which has different application methods.
- LCK Awards: Separate ticketing
- SEOULCon X World K-Pop Festival & Countdown: Separate ticketing
- 2024 SEOULCON APAN STAR AWARDS: Ticket distribution in a separate manner by the partner company
- CreativeForce Awards: An event for CreativeForce fostered by Seoul City and Seoul Business Agency. No applications for others participation.`,
            },
            {
                qKo: "신청하고 싶은 프로그램이 다 찼습니다. 참여할 수 있는 방법이 없습니까?",
                aKo: `모든 프로그램은 현장 방문 시 사전 신청자 우선으로 입장 가능하지만, 이는 인파 밀집 현상을 방지하기 위한 차원입니다. 그렇기 때문에 신청 없이 방문하셔도 현장 상황에 따라 입장 및 관람 가능합니다.
        
일부 프로그램은 행사 당일 현장 티켓 배부 또한 진행 예정입니다.
* 현장배부는 노쇼 발생 등 입장 상황을 고려하여 진행하게 되며, 안전상의 문제가 발생할 경우는 입장이 제한될 수 있습니다.`,
                qEn: "The program is full. Is there any other way Ito participate?",
                aEn: `When visiting, all programs are given priority to those who have applied in advance, but this is to prevent crowding. Therefore, even if you visit without a reservation, you could enter and watch depending on the on-site situation.
          
Some programs will distribute tickets on-site on the day of the event as well.
* On-site distribution will be conducted taking into account the admission situation, such as no-shows, and admission may be restricted in case of safety issues.`,
            },
        ]);
        setTypeKo({
            title: "서울콘 신청자를 위한 FAQ",
            attend: "신청하기 관련 FAQ",
        });
        setTypeEn({
            title: "FAQs for SEOULCon Applicants",
            attend: "FAQs on Attend",
        });
    }, []);
    const togglePostFAQ = (index: number) => {
        setOpenPostIndexes((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
    };

    const toggleAttendFAQ = (index: number) => {
        setOpenAttendIndexes((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
    };
    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-FAQ`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-FAQ`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? typeEn.title : typeKo.title}</Title>
                    <Container isBottom={false}>
                        <ListBox>
                            <Divider />
                            {posts.map((post, index) => (
                                <FAQItemComponent
                                    key={index}
                                    post={post}
                                    isOpen={openPostIndexes.includes(index)}
                                    onClick={() => togglePostFAQ(index)}
                                    language={activeLanguage}
                                />
                            ))}
                        </ListBox>
                    </Container>
                    <Title>{activeLanguage === "en" ? typeEn.attend : typeKo.attend}</Title>
                    <Container isBottom={true}>
                        <ListBox>
                            <Divider />
                            {attends.map((post, index) => (
                                <FAQItemComponent
                                    key={index}
                                    post={post}
                                    isOpen={openAttendIndexes.includes(index)}
                                    onClick={() => toggleAttendFAQ(index)}
                                    language={activeLanguage}
                                />
                            ))}
                        </ListBox>
                    </Container>
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default Faq;

const Wrap = styled(Wraps)``;

const PaddingWrap = styled(PaddingWraps)`
    @media (min-width: 1440px) {
        max-width: 1200px;
    }
`;

const Title = styled(Titles)``;

const Divider = styled(Dividers)`
    margin-bottom: 0px;
`;

const Container = styled.div<{ isBottom?: boolean }>`
    margin-bottom: ${({ isBottom }) => (isBottom ? "100px" : "72px")};
    @media (min-width: 768px) {
        margin-bottom: ${({ isBottom }) => (isBottom ? "200px" : "120px")};
    }
`;
const ListBox = styled.div`
    display: flex;
    flex-direction: column;
    a {
        margin-bottom: 16px;
    }
`;

const FAQItem = styled.div<{ isOpen: boolean }>`
    border-bottom: 4px solid ${({ isOpen }) => (isOpen ? "black" : "#ddd")};
    padding: 24px 20px;
    transition: background-color 0.3s ease, border-bottom-color 0.3s ease;

    &:hover {
        background-color: #fed800;
        border-bottom: 4px solid black;
        cursor: pointer;
    }
`;

const Question = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const QuestionText = styled.div`
    ${applyTypography("secondary", 0)};
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    img {
        margin-right: 16px;
    }
`;

const ArrowIcon = styled.img`
    /* width: 20px;
    height: 20px; */
`;

// const Answer = styled.div<{ isOpen: boolean }>`
//     max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
//     padding: ${({ isOpen }) => (isOpen ? "20px 24px" : "0 24px")};
//     background-color: #f3f3f3;
//     line-height: 22.4px;
//     ${applyTypography("secondary", 1)};
//     overflow: hidden;
//     transition: max-height 5s ease, padding 5s ease;
//     white-space: pre-wrap;
// `;

const Answer = styled.div`
    overflow: hidden;
    transition: height 0.3s ease;
`;

const AnswerContent = styled.div`
    padding: 20px 24px;
    background-color: #f3f3f3;
    line-height: 22.4px;
    ${applyTypography("secondary", 1)};
    white-space: pre-wrap;
`;
