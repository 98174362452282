import { Dim, NaviWrap } from "./StNavibar";
// import logo from "../../assets/images/main/logo-nav.svg";
import logom from "../../assets/images/main/ico-logo-m.png";
import logop from "../../assets/images/main/ico-logo-p.png";
import logo from "../../assets/images/main/newlogo.svg";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuOpen from "../../assets/images/main/ico-menu.svg";
import menuClose from "../../assets/images/main/ico-nav-close-black.svg";
import menuToggleOpen from "../../assets/images/main/ico-open-black.svg";
import menuToggleClose from "../../assets/images/main/ico-close-black.svg";
import { Wrap } from "../../pages/main/StMain";
import InfiniteRow from "./Pattern";

const Navibar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isSeoulConOpen, setIsSeoulConOpen] = useState(false);
    const [isProgramOpen, setIsProgramOpen] = useState(false);
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    const navRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();

    const getPathWithoutLang = (path: any) => path.replace(/^\/(ko|en)/, "");

    const currentPath = getPathWithoutLang(location.pathname);

    const isSeoulConSubMenuActive = ["/main", "/seoulcon", "/about", "/bi", "/lineup", "/goods"].includes(currentPath);

    const isProgramSubMenuActive = ["/festival", "/commerce", "/contents", "/performance", "/wintafesta"].includes(
        currentPath
    );

    const isInfoSubMenuActive = ["/timeline", "/tourist", "/maps"].includes(currentPath);

    const isAttendSubMenuActive = ["/attend"].includes(currentPath);

    const isNoticeSubMenuActive = ["/notice"].includes(currentPath);

    const navigate = useNavigate();

    const date = "2024-12-28";

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (navRef.current && !navRef.current.contains(event.target as Node)) {
                setMobileMenuOpen(false);
                setIsSeoulConOpen(false);
                setIsProgramOpen(false);
                setIsInfoOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
        // const currentPath = window.location.pathname;
        // console.log(currentPath);
    }, [activeLanguage]);

    const handleButtonClick = (language: string) => {
        sessionStorage.setItem("lang", language);
        setActiveLanguage(language);

        const currentPath = location.pathname;
        const queryString = location.search; // 현재 URL의 쿼리스트링
        const newPath = `${currentPath.replace(/^\/(ko|en)/, `/${language}`)}${queryString}`;

        document.location.href = newPath;
    };

    const refreshPage = (path: any) => {
        if (location.pathname === path) {
            navigate(0);
        }
    };
    return (
        <>
            <Wrap>
                <Dim
                    className={`dim ${isMobileMenuOpen ? "visible" : ""}`}
                    onClick={() => setMobileMenuOpen(false)}
                ></Dim>
                <NaviWrap
                    ref={navRef}
                    $isSeoulConOpen={isSeoulConOpen}
                    $isProgramOpen={isProgramOpen}
                    $isInfoOpen={isInfoOpen}
                    $isScrolled={isScrolled}
                >
                    <div className="padding">
                        <div className="padding-inner">
                            <div className="background-blur" />
                            <div className="left">
                                <Link to={`/${activeLanguage}/`}>
                                    <img
                                        className="logo"
                                        src={logo}
                                        alt="logo"
                                        style={{
                                            filter: isMobileMenuOpen ? "brightness(50%)" : "brightness(100%)",
                                        }}
                                        onClick={() => (window.location.href = `/${activeLanguage}`)}
                                    />
                                </Link>
                            </div>

                            <button className="mobile-menu-toggle" onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                                <img src={isMobileMenuOpen ? menuClose : menuOpen} alt="menuToggle" />
                            </button>

                            <div className={`right ${isMobileMenuOpen ? "open" : ""}`}>
                                <div
                                    className="menu-container"
                                    onMouseEnter={() => {
                                        if (window.innerWidth > 767) {
                                            setIsSeoulConOpen(true);
                                            setIsProgramOpen(false);
                                            setIsInfoOpen(false);
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (window.innerWidth > 767) {
                                            setIsSeoulConOpen(false);
                                        }
                                    }}
                                >
                                    <button
                                        className={`menuBtn ${isSeoulConSubMenuActive ? "active" : ""}`}
                                        onClick={() => {
                                            if (window.innerWidth <= 768) {
                                                setIsProgramOpen(false);
                                                setIsInfoOpen(false);
                                                setIsSeoulConOpen(!isSeoulConOpen);
                                            }
                                        }}
                                    >
                                        <span>SEOULCon</span>
                                        <div>
                                            <img
                                                src={isSeoulConOpen ? menuToggleClose : menuToggleOpen}
                                                alt="메뉴 열기"
                                                className="toggle"
                                            />
                                        </div>
                                    </button>
                                    {isSeoulConOpen && (
                                        <div className="submenu">
                                            <Link
                                                to={`/${activeLanguage}/about`}
                                                onClick={() => refreshPage(`/${activeLanguage}/about`)}
                                            >
                                                <button>About</button>
                                            </Link>
                                            {/* <span>
                                        <button onClick={() => refreshPage(`/${activeLanguage}/about`)}>About</button>
                                    </span> */}

                                            <Link
                                                to={`/${activeLanguage}/bi`}
                                                onClick={() => refreshPage(`/${activeLanguage}/bi`)}
                                            >
                                                <button>BI</button>
                                            </Link>

                                            <Link
                                                to={`/${activeLanguage}/lineup`}
                                                onClick={() => refreshPage(`/${activeLanguage}/lineup`)}
                                            >
                                                <button>Influencer line up</button>
                                            </Link>

                                            {/* <Link to='/goods'>
                    <button>Goods</button>
                  </Link> */}
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="menu-container"
                                    onMouseEnter={() => {
                                        if (window.innerWidth > 767) {
                                            setIsProgramOpen(true);
                                            setIsSeoulConOpen(false);
                                            setIsInfoOpen(false);
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (window.innerWidth > 767) {
                                            setIsProgramOpen(false);
                                        }
                                    }}
                                >
                                    <button
                                        className={`menuBtn2 ${isProgramSubMenuActive ? "active" : ""}`}
                                        onClick={() => {
                                            if (window.innerWidth <= 768) {
                                                setIsSeoulConOpen(false);
                                                setIsInfoOpen(false);
                                                setIsProgramOpen(!isProgramOpen);
                                            }
                                        }}
                                    >
                                        <span>Program</span>
                                        <div>
                                            <img
                                                src={isProgramOpen ? menuToggleClose : menuToggleOpen}
                                                alt="메뉴 열기"
                                                className="toggle"
                                            />
                                        </div>
                                    </button>
                                    {isProgramOpen && (
                                        <div
                                            className="submenu col"
                                            onMouseEnter={() => setIsProgramOpen(true)}
                                            onMouseLeave={() => setIsProgramOpen(false)}
                                        >
                                            <Link
                                                to={`/${activeLanguage}/contents`}
                                                onClick={() => refreshPage(`/${activeLanguage}/contents`)}
                                            >
                                                <button>Content/Fashion/Beauty</button>
                                            </Link>
                                            <Link
                                                to={`/${activeLanguage}/festival`}
                                                onClick={() => refreshPage(`/${activeLanguage}/festival`)}
                                            >
                                                <button>Festival</button>
                                            </Link>

                                            <Link
                                                to={`/${activeLanguage}/performance`}
                                                onClick={() => refreshPage(`/${activeLanguage}/performance`)}
                                            >
                                                <button>Influencer/Entertainment</button>
                                            </Link>
                                            <Link
                                                to={`/${activeLanguage}/commerce`}
                                                onClick={() => refreshPage(`/${activeLanguage}/commerce`)}
                                            >
                                                <button>Commerce</button>
                                            </Link>

                                            <Link
                                                to={`/${activeLanguage}/wintafesta`}
                                                onClick={() => refreshPage(`/${activeLanguage}/wintafesta`)}
                                            >
                                                <button>Winta Festa</button>
                                            </Link>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="menu-container"
                                    onMouseEnter={() => {
                                        if (window.innerWidth > 767) {
                                            setIsInfoOpen(true);
                                            setIsSeoulConOpen(false);
                                            setIsProgramOpen(false);
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (window.innerWidth > 767) {
                                            setIsInfoOpen(false);
                                        }
                                    }}
                                >
                                    <button
                                        className={`menuBtn3 ${isInfoSubMenuActive ? "active" : ""}`}
                                        onClick={() => {
                                            if (window.innerWidth <= 768) {
                                                setIsProgramOpen(false);
                                                setIsSeoulConOpen(false);
                                                setIsInfoOpen(!isInfoOpen);
                                            }
                                        }}
                                    >
                                        <span>Information</span>
                                        <div>
                                            <img
                                                src={isInfoOpen ? menuToggleClose : menuToggleOpen}
                                                alt="메뉴 열기"
                                                className="toggle"
                                            />
                                        </div>
                                    </button>
                                    {isInfoOpen && (
                                        <div
                                            className="submenu"
                                            onMouseEnter={() => setIsInfoOpen(true)}
                                            onMouseLeave={() => setIsInfoOpen(false)}
                                        >
                                            {/* <Link
                                                to={`/${activeLanguage}/timeline?date=${date}`}
                                                onClick={() => refreshPage(`/${activeLanguage}/timeline?date=${date}`)}
                                            >
                                                <button>Timeline</button>
                                            </Link> */}

                                            <Link
                                                to={`/${activeLanguage}/maps`}
                                                onClick={() => refreshPage(`/${activeLanguage}/maps`)}
                                            >
                                                <button>Guide Map</button>
                                            </Link>

                                            <Link
                                                to={`/${activeLanguage}/tourist`}
                                                onClick={() => refreshPage(`/${activeLanguage}/tourist`)}
                                            >
                                                <button>Tourist Sights</button>
                                            </Link>
                                        </div>
                                    )}
                                </div>

                                <div className="menu-container">
                                    <Link
                                        to={`/${activeLanguage}/attend`}
                                        onClick={() => refreshPage(`/${activeLanguage}/attend`)}
                                    >
                                        <button className={`menuBtn4 ${isAttendSubMenuActive ? "active" : ""}`}>
                                            <span>Attend</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="menu-container">
                                    <Link
                                        to={`/${activeLanguage}/notice`}
                                        onClick={() => refreshPage(`/${activeLanguage}/notice`)}
                                    >
                                        <button className={`menuBtn4 ${isNoticeSubMenuActive ? "active" : ""}`}>
                                            <span>Notice</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="button-lang">
                                    <button
                                        className={`ko ${activeLanguage === "ko" ? "active" : ""}`}
                                        onClick={() => handleButtonClick("ko")}
                                    >
                                        KO
                                    </button>
                                    <hr />
                                    <button
                                        className={`en ${activeLanguage === "en" ? "active" : ""}`}
                                        onClick={() => handleButtonClick("en")}
                                    >
                                        EN
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="lang">
                            <button
                                className={`ko ${activeLanguage === "ko" ? "active" : ""}`}
                                onClick={() => handleButtonClick("ko")}
                            >
                                KO
                            </button>
                            <hr />
                            <button
                                className={`en ${activeLanguage === "en" ? "active" : ""}`}
                                onClick={() => handleButtonClick("en")}
                            >
                                EN
                            </button>
                        </div>
                    </div>
                    <div className="separate"></div>
                </NaviWrap>
            </Wrap>
            <InfiniteRow />
            <InfiniteRow oddColor="#fed800" evenColor="#036eb8" top="92px" mTop="68px" />
        </>
    );
};

export default Navibar;
