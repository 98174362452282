import styled from "styled-components";
import { applyTypography } from "../styles/mixins";

const Titles = styled.h1`
    ${applyTypography("primary", 1)};
    position: relative;
    margin-top: 46px;

    @media (min-width: 768px) {
        margin-top: 42px;
    }
`;

export default Titles;
