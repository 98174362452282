import styled from "styled-components";

const Dividers = styled.hr`
    width: 100%;
    border-bottom: 4px solid black;
    margin-top: 28px;
    margin-bottom: 24px;

    @media (min-width: 768px) {
        margin-top: 36px;
        margin-bottom: 32px;
    }
`;

export default Dividers;
