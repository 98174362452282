import { useEffect, useState } from "react";
import styled from "styled-components";

// Props 타입 정의
interface InfiniteRowProps {
    oddColor?: string;
    evenColor?: string;
    top?: string;
    mTop?: string;
}

const Wrapper = styled.div<{ top: string; mTop: string }>`
    display: flex;
    overflow-x: hidden;
    width: 100%;
    background: #000000;
    position: fixed;
    top: ${({ top }) => top};
    z-index: 150;
    @media (max-width: 768px) {
        top: ${({ mTop }) => mTop};
    }
`;
interface ItemProps {
    oddColor: string;
    evenColor: string;
}

const Item = styled.div<ItemProps>`
    width: 12px;
    height: 16px;
    box-sizing: border-box;
    border-top: 4px solid #000000;
    border-left: 4px solid #000000;
    border-bottom: 4px solid #000000;

    &:nth-child(odd) {
        background: ${({ oddColor }) => oddColor};
    }

    &:nth-child(even) {
        background: ${({ evenColor }) => evenColor};
    }
`;

const InfiniteRow: React.FC<InfiniteRowProps> = ({
    oddColor = "#c30d23",
    evenColor = "#006934",
    top = "80px",
    mTop = "56px",
}) => {
    const [itemsCount, setItemsCount] = useState(Math.ceil(window.innerWidth / 12));

    // Resize 이벤트를 감지하여 itemsCount 업데이트
    useEffect(() => {
        const handleResize = () => {
            setItemsCount(Math.ceil(window.innerWidth / 12));
        };

        window.addEventListener("resize", handleResize);
        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const items = Array.from({ length: itemsCount });

    return (
        <Wrapper top={top} mTop={mTop}>
            {items.map((_, index) => (
                <Item key={index} oddColor={oddColor} evenColor={evenColor} />
            ))}
        </Wrapper>
    );
};

export default InfiniteRow;
