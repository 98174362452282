import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import calendar from "../../assets/images/info/ico-calendar-b.svg";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Titles from "../../components/Title";
import Dividers from "../../components/Dividers";
import { Link, useLocation } from "react-router-dom";
import { applyTypography } from "../../styles/mixins";
import axios from "axios";
import { APIURL } from "../../constants";

type Program = {
    id: number;
    titleKo: string;
    titleEn: string;
    date: string;
    startTime: string;
    endTime: string;
    placeKo: string;
    placeEn: string;
    positionKo: string;
    positionEn: string;
    contentKo: string;
    contentEn: string;
    category: string;
};

type PositionData = {
    positionKo: string;
    positionEn: string;
    programs: Program[];
};
const startTime = 9; // 시작 시간 (09:00)
const totalHours = 21; // 09:00 ~ 다음날 06:00까지 총 21시간

const TimelineGrid = () => {
    // 30분 간격으로 라인 생성
    const timeSlots = [];
    for (let i = 0; i <= totalHours * 2; i++) {
        timeSlots.push({
            isDotted: i % 2 === 1, // 홀수이면 점선
        });
    }

    return (
        <TimelineContainer>
            {timeSlots.map((slot, index) => (
                <TimeSlot key={index} isDotted={slot.isDotted} />
            ))}
        </TimelineContainer>
    );
};
const TimelineGridT = () => {
    // 30분 간격으로 시간 라벨 생성
    const timeSlots = [];
    for (let i = 0; i <= totalHours * 2; i++) {
        const hours = Math.floor(i / 2) + startTime;
        const actualHours = hours >= 24 ? hours - 24 : hours;
        const minutes = i % 2 === 0 ? "00" : "30";
        timeSlots.push({
            timeLabel: minutes === "00" ? `${actualHours < 10 ? "0" : ""}${actualHours}:00` : "",
            // isDotted: i % 2 === 1, // 홀수이면 점선
        });
    }

    return (
        <TimelineContainerT>
            {timeSlots.map((slot, index) => (
                <TimeSlots key={index}>
                    <TimeLabel>{slot.timeLabel}</TimeLabel>
                </TimeSlots>
            ))}
        </TimelineContainerT>
    );
};

const Timeline = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [data, setData] = useState<PositionData[]>([]);
    const location = useLocation();

    // Retrieve the `date` query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const date = queryParams.get("date");
    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
        const fetchData = async () => {
            try {
                const response = await axios.get(`${APIURL}/program/timeline?date=${date}`);
                console.log(response.data.data);
                setData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [date]);

    const calculateTimeDifference = (startTime: string, endTime: string) => {
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        const [endHours, endMinutes] = endTime.split(":").map(Number);
        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;

        return endTotalMinutes - startTotalMinutes;
    };

    const calculateMarginTop = (startTime: string) => {
        const [hours, minutes] = startTime.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;
        const startMinutes = 9 * 60; // Starting point at 09:00
        const difference = totalMinutes - startMinutes;
        return (difference / 30) * 40; // Scaling each 30 minutes to 40px
    };

    const days = [
        { date: "2024-12-28", day: "Day 01", labelEn: "2024-12-28 (Sat)", labelKo: "2024-12-28 (토)" },
        { date: "2024-12-29", day: "Day 02", labelEn: "2024-12-29 (Sun)", labelKo: "2024-12-29 (일)" },
        { date: "2024-12-30", day: "Day 03", labelEn: "2024-12-30 (Mon)", labelKo: "2024-12-30 (월)" },
        { date: "2024-12-31", day: "Day 04", labelEn: "2024-12-31 (Thu)", labelKo: "2024-12-31 (화)" },
    ];

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Timeline`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-타임라인`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? "Timeline" : "전체 타임라인"}</Title>
                    <Divider />
                    <Contents>
                        <TopMenu>
                            {days.map((day) => (
                                <StyleLink key={day.date} to={`?date=${day.date}`} isActive={date === day.date}>
                                    <div className="dayBox">
                                        <img src={calendar} />
                                        <div className="day">{day.day}</div>
                                    </div>
                                    <div className="date">{activeLanguage === "en" ? day.labelEn : day.labelKo}</div>
                                </StyleLink>
                            ))}
                        </TopMenu>
                    </Contents>
                </PaddingWrap>
                <PaddingWrapList>
                    {<TimelineGridT></TimelineGridT>}
                    {<TimelineGrid></TimelineGrid>}
                    <ScrollBox>
                        {data.length > 0 ? (
                            <DataContainer>
                                {data.map((position) => {
                                    return (
                                        <ScheduleContainer>
                                            <ScheduleTitle
                                                color={
                                                    position.positionKo === "아트홀"
                                                        ? "#c30d23"
                                                        : position.positionKo === "디자인 랩" ||
                                                          position.positionKo === "디자인랩"
                                                        ? "#036eb8"
                                                        : position.positionKo === "맥스타일"
                                                        ? "#006834"
                                                        : "#fed800"
                                                }
                                                font={position.positionKo === "이간수문전시장" ? true : false}
                                            >
                                                {activeLanguage === "en" ? position.positionEn : position.positionKo}
                                            </ScheduleTitle>
                                            <ScheduleBox>
                                                {position.programs.map((value) => {
                                                    const height =
                                                        (calculateTimeDifference(value.startTime, value.endTime) / 30) *
                                                        40;
                                                    const marginTop = calculateMarginTop(value.startTime);

                                                    return (
                                                        <ScheduleList
                                                            key={value.id}
                                                            bgColor={
                                                                position.positionKo === "아트홀"
                                                                    ? "#fff0f2"
                                                                    : position.positionKo === "디자인 랩" ||
                                                                      position.positionKo === "디자인랩"
                                                                    ? "#ecf7ff"
                                                                    : position.positionKo === "맥스타일"
                                                                    ? "#ecfff5"
                                                                    : "#fff9d9"
                                                            }
                                                            color={
                                                                position.positionKo === "아트홀"
                                                                    ? "#c30d23"
                                                                    : position.positionKo === "디자인 랩" ||
                                                                      position.positionKo === "디자인랩"
                                                                    ? "#036eb8"
                                                                    : position.positionKo === "맥스타일"
                                                                    ? "#006834"
                                                                    : "#fed800"
                                                            }
                                                            height={height}
                                                            font={
                                                                position.positionKo === "이간수문전시장" ? true : false
                                                            }
                                                        >
                                                            <p className="place">
                                                                {activeLanguage === "en"
                                                                    ? value.placeEn
                                                                    : value.placeKo}
                                                            </p>
                                                            <ScheduleLink
                                                                marginTop={marginTop}
                                                                to={`/${activeLanguage}/${value.category}/${value.id}`}
                                                            >
                                                                <div className="content">
                                                                    {activeLanguage === "en"
                                                                        ? value.titleEn
                                                                        : value.titleKo}
                                                                </div>
                                                            </ScheduleLink>
                                                        </ScheduleList>
                                                    );
                                                })}
                                            </ScheduleBox>
                                        </ScheduleContainer>
                                    );
                                })}
                            </DataContainer>
                        ) : (
                            <p>데이터를 불러오는 중입니다...</p>
                        )}
                    </ScrollBox>
                </PaddingWrapList>
            </Wrap>
            <Footer />
        </>
    );
};

export default Timeline;

const Wrap = styled(Wraps)`
    flex-direction: column;
`;
const PaddingWrap = styled(PaddingWraps)`
    margin: 0 auto;
    padding-top: 84px;
`;
const PaddingWrapList = styled(PaddingWraps)`
    width: 100%;
    margin-top: 0px;
    height: 2000px;
    @media (min-width: 768px) {
        width: 92%;
        margin: 0 auto;
    }
`;
const Title = styled(Titles)``;

const Divider = styled(Dividers)`
    margin-bottom: 8px;
`;

const Contents = styled.div`
    position: relative;
`;
const fadeInUpAnimation = `
    @keyframes fadeInUp2 {
        from { opacity: 0; transform: translateY(3%); }
        to { opacity: 1; transform: translateY(0); }
    }

    .custom-fadeInUp2 {
        animation: fadeInUp2 0.5s ease-in-out;
    }
`;
const TopMenu = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`;
const StyleLink = styled(Link)<{ isActive: boolean }>`
    display: flex;
    text-decoration: none;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 4px #000;
    margin-top: 16px;
    width: 48%;
    opacity: ${({ isActive }) => (isActive ? "1" : "0.2")};

    .dayBox {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .day {
        ${applyTypography("primary", 3)};
    }
    .date {
        margin-top: 8px;
        ${applyTypography("secondary", 1)};
        padding-bottom: 24px;
    }

    @media (min-width: 1440px) {
        width: 24%;
    }
`;
const ScrollBox = styled.div`
    display: flex;
    overflow-x: scroll;
    width: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
    height: 2000px;
    position: absolute;
    top: 0px;
`;

const DataContainer = styled.div`
    display: flex;
    margin-top: 44px;
    margin-left: 90px;
    display: flex;
`;

const ScheduleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;
`;
const ScheduleTitle = styled.div<{ color?: string; font?: boolean }>`
    ${applyTypography("primary", 4)};
    height: 44px;
    background-color: ${({ color }) => color};
    color: ${({ font }) => (font ? "#000" : "#fff")};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
`;
const ScheduleBox = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
`;

const ScheduleList = styled.div<{
    color?: string;
    height: number;
    font?: boolean;
    bgColor?: string;
}>`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .place {
        display: flex;
        ${applyTypography("secondary", 0)};
        height: 55px;
        @media (min-width: 768px) {
            height: 66px;
        }
    }
    .content {
        ${applyTypography("secondary", 2)};
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        border: solid 4px ${({ color }) => color};
        box-sizing: border-box;
        height: ${({ height }) => `${height}px`};
        position: relative;
        background-color: #ffffff;
        z-index: 1;

        text-align: center;
        padding: 0px 10px;
        overflow-wrap: break-word;
        &:hover {
            background-color: ${({ bgColor }) => bgColor};
            transition: background-color 0.3s ease;
            color: #000000;
        }
    }
`;
const ScheduleLink = styled(Link)<{ marginTop: number }>`
    margin-top: ${({ marginTop }) => `${3 + marginTop}px`};
`;

const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 93.5%;
    background-color: #ffffff;
    margin-left: 80px;
    position: absolute;
    top: 0px;
    margin-top: 169px;
    @media (min-width: 768px) {
        margin-top: 180px;
    }
`;
const TimelineContainerT = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 25;
    width: 80px;
    background-color: white;
    padding-top: 151px;
    left: 0px;
    @media (min-width: 768px) {
        padding-top: 161px;
    }
`;

const TimeSlot = styled.div<{ isDotted?: boolean }>`
    height: 40px;
    border-top: ${(props) => (props.isDotted ? "4px dotted #ebebeb" : "4px solid #ebebeb")};
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
`;
const TimeSlots = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
`;
const TimeLabel = styled.span`
    ${applyTypography("secondary", 1)};

    color: #000000;
    position: absolute;
`;
// const TimeIndex = styled.div`
//     height: 2000px;
//     width: 80px;
//     position: relative;
//     display: flex;
//     background-color: white;
// `;
// const TimeLabels = styled.div`
//     display: flex;
//     flex-direction: column;
//     width: 60px;
//     z-index: 10;
// `;
// const TimeLabel = styled.div`
//     height: 40px;
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     color: #000000;
//     font-size: 14px;
//     padding-right: 10px;
//     box-sizing: border-box;
// `;

// const TimeLines = styled.div`
//     flex: 1;
//     display: flex;
//     flex-direction: column;
// `;

// const Line = styled.div<{ isDotted: boolean }>`
//     height: 40px;
//     border-top: ${(props) => (props.isDotted ? "4px dotted #ebebeb" : "4px solid #ebebeb")};
//     box-sizing: border-box;
// `;
const hexToRgba = (hex: string, alpha: number) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
