import styled, { keyframes } from "styled-components";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import Wraps from "../../components/Wraps";

const fillBarAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

export const Wrap = styled(Wraps)``;

export const MainWrap = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;

    .banner-container {
        position: relative;
        width: 100%;
        height: 100%;

        .slick-slide {
            width: 100%;
            height: 100%;
            position: relative;
        }

        div {
            .image-wrapper::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 2;
            }

            .banner-image {
                width: 100%;
                height: 100vh;
                object-fit: cover;
            }
        }
    }

    .banner-image.slide-left {
        transform: translateX(-100%);
        transition: transform 1s ease-in-out;
    }

    .banner-image.slide-right {
        transform: translateX(0);
        transition: transform 1s ease-in-out;
    }

    @media (max-width: 550px) {
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;

        .banner-container {
            div {
                .image-wrapper {
                    height: 100%;

                    .banner-image {
                        width: 100%;
                        height: 450px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
`;

export const TextCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
    width: 70%;
    z-index: 9;
    white-space: pre-wrap;

    p {
        color: white;
        font-size: 1.375rem;
        font-weight: 700;
        font-family: var(--font--bold);
    }

    h1 {
        color: white;
        font-size: 56px;
        margin-top: 0px;
        margin-bottom: 80px;
        line-height: 74px;
        font-family: var(--font--bold);

        @media (max-width: 312px) {
            line-height: 40px;
        }
    }

    .custom-fadeInUp {
        animation: fadeInUp 2s ease forwards;
        transform: translateY(15%);
    }

    .custom-fadeInUp2 {
        animation: fadeInUp2 1s ease forwards;
        transform: translateY(50%);
    }

    @keyframes fadeInUp2 {
        from {
            opacity: 0;
            transform: translateY(50%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes custom-fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    button {
        width: 239px;
        height: 52px;
        background: none;
        color: white;
        border: 1px solid white;
        border-radius: 50px;
        cursor: pointer;
        font-size: 1rem;
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        transition: background-color 0.3s ease, color 0.3s ease;

        img {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }

    button:hover {
        background: white;
        color: black;
    }

    button:hover img {
        content: url(${buttonArrowBlack});
    }

    @media (max-width: 820px) {
        button {
            pointer-events: none;
        }
    }

    @media (max-width: 550px) {
        h1 {
            font-size: 30px;
            margin-bottom: 30px;
            line-height: 42px;
        }
        p {
            font-size: 16px;
        }
    }
`;

export const BtnBetween = styled.div`
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .prev,
    .next {
        position: relative;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        overflow: hidden;
    }

    .prev img,
    .next img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s ease;
    }

    .prev img:last-child,
    .next img:last-child {
        opacity: 0;
    }

    .prev:hover img:last-child,
    .next:hover img:last-child {
        opacity: 1;
    }

    .prev:hover img:first-child,
    .next:hover img:first-child {
        opacity: 0;
    }

    .prev {
        margin-left: 50px;
    }

    .next {
        margin-right: 50px;
    }

    .prev:hover,
    .next:hover {
        transition: 3s !important;
    }

    @media (max-width: 820px) {
        .prev:hover img:last-child,
        .next:hover img:last-child {
            opacity: 0;
        }

        .prev:hover img:first-child,
        .next:hover img:first-child {
            opacity: 1;
        }
    }

    @media (max-width: 550px) {
        .prev,
        .next {
            width: 45px;
            height: 45px;
        }
        .prev {
            margin-left: 10px;
        }

        .next {
            margin-right: 10px;
        }
    }
`;

export const FillBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: white;
    animation: ${fillBarAnimation} 6s linear;
`;

export const Bottom = styled.div`
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 9;
    height: 2px;

    .btnbox {
        position: relative;
        display: flex;
        z-index: 9;
        width: 55px;
        height: 2px;
        margin: 0px 1px;
        background: linear-gradient(0deg, #565656, #565656), linear-gradient(0deg, #3a3a3a, #3a3a3a);
    }

    @media (max-width: 550px) {
        bottom: 20px;
    }
`;

export const YoutubeWrap = styled.div`
    width: 100%;
    height: auto;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;

    img {
        width: 372px;
        position: absolute;
        top: 130px;
        left: 15%;
    }

    h1 {
        font-size: 3rem;
        color: white;
        margin-bottom: 10px;
        font-weight: 700;
        position: relative;
        font-family: var(--font--bold);
    }

    p {
        font-size: 1.125rem;
        color: white;
        margin-bottom: 50px;
        position: relative;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;

        video {
            width: 90%;
            object-fit: cover;
            z-index: 10;
        }
    }

    div.visible {
        visibility: visible;
    }

    .custom-fadeInUp {
        animation: fadeInUp 1s ease forwards;
        transform: translateY(15%);
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 820px) {
        img {
            position: absolute;
            top: 100px;
            left: -10%;
        }
    }

    @media (max-width: 550px) {
        padding-top: 72px;
        padding-bottom: 72px;

        img {
            display: none;
        }

        h1 {
            font-size: 26px;
        }

        p {
            font-size: 16px;
            width: 90%;
            text-align: center;
            line-height: 1.5rem;
        }
    }
`;

export const LineupWrap = styled.div`
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 120px;
    height: auto;
    min-height: 720px;

    .padding {
        width: 80%;
        max-width: 1544px;
        position: relative;
        overflow: visible;
    }

    .text_top {
        width: 100%;
        height: 109px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: left;

        h1 {
            font-size: 3rem;
            margin-top: 0px;
            color: black;
            font-family: var(--font--bold);
        }

        p {
            font-size: 1.125rem;
            font-weight: 500;
            margin-top: 0px;
            color: black;
        }
    }

    .right {
        button {
            width: 239px;
            height: 52px;
            border: 1px solid black;
            border-radius: 50px;
            background: none;
            font-size: 1rem;
            cursor: pointer;
            color: black;

            img {
                width: 12px;
                height: 12px;
                margin-left: 5px;
                transition: 0.3s;
                z-index: 999;
            }
        }

        button:hover img {
            content: url(${buttonArrow});
        }

        button:hover {
            background: black;
            color: white;
            transition: 0.2s;
        }
    }
    .scroll-paused {
        animation-play-state: paused;
    }

    .imgBox {
        width: 100%;
        display: flex;
        scrollbar-width: none;
        -ms-overflow-style: none;
        white-space: nowrap;
        margin-top: 0px;
        z-index: 9;
        position: relative;
        overflow-x: auto;
        visibility: hidden;
        cursor: pointer;
        padding-left: 100px;

        &::-webkit-scrollbar {
            display: none;
        }

        .scroll,
        .scroll.clone {
            display: flex;
            animation: slide 120s linear infinite;
        }

        @keyframes slide {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-50%);
            }
        }

        .imgContainer {
            width: auto;
            position: relative;
            display: inline-block;
            z-index: 2;

            .width {
                width: 260px;
                height: 260px;
                border-radius: 50%;
                overflow: hidden;
                position: relative;
                margin-right: 20px;
            }

            .text {
                position: absolute;
                top: 49%;
                left: 46%;
                transform: translate(-50%, -50%);
                margin: 0;
                padding: 0;
                z-index: 10;
                display: flex;
                flex-direction: column;
                text-align: center;
            }

            img {
                position: relative;
                width: 100%;
                height: 100%;
                z-index: 9;
                transition: transform 0.3s ease, filter 0.3s ease;
                transform-origin: center center;
                object-fit: cover;
            }

            &:hover img {
                transform: scale(1.1);
                filter: brightness(0.3);
            }

            .hoverText,
            b {
                display: none;
                z-index: 10;
            }

            span {
                color: rgba(82, 243, 255, 1);
                font-size: 16px;
                color: white;
                font-weight: 700;
                margin-bottom: 10px;
                text-align: center;
            }

            b {
                color: rgba(82, 243, 255, 1);
                font-size: 36px;
                font-weight: 700;
                font-family: var(--font--bold);
            }

            &:hover .hoverText,
            &:hover b {
                display: block;
            }
        }
    }

    .imgBox.visible {
        visibility: visible;
    }

    .imgBox.animate__animated {
        animation-delay: 0.1s;
    }

    @media (max-width: 1000px) {
        min-height: 700px;
    }

    @media (max-width: 820px) {
        min-height: 780px;

        .text_top {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            .left {
                width: 100%;
                padding: 0;
                align-items: flex-start;

                p {
                    margin-top: -5px;
                }
            }

            .right {
                width: 100%;
                padding: 0;
                margin-top: -50px;
                margin-bottom: 60px;

                button {
                    pointer-events: none;
                    margin-top: 80px;
                }
            }
        }

        .imgBox {
            margin-top: 100px;

            .imgContainer {
                .width {
                    width: 260px;
                }
            }
        }
    }

    @media (max-width: 550px) {
        min-height: 730px;
        margin-top: 72px;

        .padding {
            width: 90%;
        }

        .text_top {
            width: 90%;

            .left {
                h1 {
                    font-size: 26px;
                }
                p {
                    font-size: 16px;
                    line-height: 1.5rem;
                }
            }
        }

        .imgBox {
            margin-top: 44px;

            .imgContainer {
                .width {
                    width: 200px;
                    height: 200px;
                }
                span {
                    font-size: 16px;
                }

                b {
                    font-size: 26px;
                }
            }
        }
    }

    @media (max-width: 423px) {
        min-height: 900px;
    }
`;

export const ProgramWrap = styled.div`
    width: 100%;
    height: auto;
    background: black;
    display: flex;
    justify-content: center;
    margin-top: -350px;
    position: relative;

    .triangle {
        width: 446px;
        position: absolute;
        top: 350px;
        right: 0px;
    }

    .padding {
        width: 80%;
        max-width: 1544px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .text2 {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: flex-start;
            margin-top: 200px;
            margin-bottom: 30px;

            h1 {
                font-size: 3rem;
                color: white;
                font-family: var(--font--bold);
            }
            p {
                font-size: 1.125rem;
                font-weight: 500;
                margin-top: -8px;
                color: white;
            }
        }

        .imgWrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 100px;
            visibility: hidden;

            .left {
                margin-right: 20px;
            }

            .left,
            .right {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .box {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(47, 83, 0, 0.5) 100%);
                        opacity: 0;
                        transition: opacity 0.3s ease;
                        pointer-events: none;
                    }

                    &:hover::after {
                        opacity: 1;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        z-index: 0;
                        position: relative;
                        transition: transform 0.5s;
                        cursor: pointer;
                        will-change: transform;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                p {
                    font-size: 1rem;
                    color: #bfff6a;
                }

                h5 {
                    font-size: 2.25rem;
                    color: white;
                    margin-top: 0px;
                    margin-bottom: 30px;
                    font-family: var(--font--bold);
                }
            }

            .first.animate__animated {
                animation-delay: 0.1s;
            }

            .second.animate__animated {
                animation-delay: 0.2s;
            }

            .third.animate__animated {
                animation-delay: 0.3s;
            }

            .fourth.animate__animated {
                animation-delay: 0.4s;
            }

            .fifth.animate__animated {
                animation-delay: 0.5s;
            }
            .fifth {
                width: 50%;
            }
        }

        .visible {
            visibility: visible;
        }
    }

    .custom-fadeInUp {
        animation: fadeInUp 1s ease forwards;
        transform: translateY(15%);
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 1000px) {
        margin-top: -350px;
    }

    @media (max-width: 820px) {
        .triangle {
            display: none;
        }

        .padding {
            .imgWrap {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .left {
                    margin-bottom: 44px;
                    margin-right: 0;
                    width: 100%;
                }
                .right {
                    margin-bottom: -44px;
                    width: 100%;
                }

                .box {
                    pointer-events: none;

                    img {
                        pointer-events: none;
                    }
                }
                .fifth {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 550px) {
        margin-top: -350px;

        .padding {
            width: 90%;

            .imgWrap {
                .left {
                    margin-bottom: 30px;
                    h5 {
                        font-size: 20px;
                    }
                }

                .right {
                    margin-bottom: -70px;
                    h5 {
                        font-size: 20px;
                    }
                }
            }

            .text2 {
                h1 {
                    font-size: 26px;
                    margin-bottom: 20px;
                    margin-top: -50px;
                }

                p {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
    }

    @media (max-width: 420px) {
        margin-top: -530px;

        .padding {
            width: 90%;

            .text2 {
                h1 {
                    font-size: 26px;
                    margin-bottom: 30px;
                    margin-top: -30px;
                }
                p {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
            .imgWrap {
                .left {
                    margin-right: 0;
                }

                .left,
                .right {
                    .box {
                        max-width: 100%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
`;

export const NoticeWrap = styled.div`
    width: 100%;
    height: auto;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    position: relative;
    padding-bottom: 200px;

    h1 {
        font-size: 3rem;
        color: white;
        margin-bottom: 10px;
        font-family: var(--font--bold);
    }

    p {
        font-size: 1.125rem;
        color: white;
        margin-bottom: 50px;
    }

    .content {
        width: 100%;
        max-width: 60%;

        .top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            .left {
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: start;

                span:first-child {
                    flex: 0 0 45px;
                    margin-left: 10px;
                }

                span:nth-child(2) {
                    flex: 2;
                    margin-left: 40px;
                }
            }

            .right {
                width: 20%;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                span:first-child {
                    flex: 0 0 110px;
                    text-align: left;
                }

                span:nth-child(2) {
                    flex: 0 0 130px;
                    text-align: center;
                }
            }
            span {
                color: white;
            }
        }

        hr {
            border-top: 1px solid white;
            margin: 0px 0;
        }

        .listBox {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .list {
                display: flex;
                align-items: center;
                width: 100%;
                height: 65px;
                border-bottom: 1px solid rgba(58, 58, 58, 1);
                cursor: pointer;

                .rightBox {
                    width: 220px;
                    display: flex;
                    justify-content: space-between;

                    .viewsText {
                        display: none;
                    }
                }

                &:hover {
                    background: #171717;
                    transition: 0.1s;
                }

                .num {
                    flex: 0 1 50px;
                    text-align: center;
                    margin-left: 20px;
                }

                .title {
                    flex: 1;
                    text-align: left;
                    padding-left: 45px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .views {
                    padding-right: 21px;
                }
            }

            span {
                color: white;
            }
        }
    }

    button {
        width: 239px;
        height: 52px;
        border: 1px solid white;
        border-radius: 50px;
        background: none;
        font-size: 1rem;
        cursor: pointer;
        margin-top: 50px;
        color: white;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }
    button:hover {
        background: white;
        color: black;
        transition: 0.3s;
    }

    button:hover img {
        content: url(${buttonArrowBlack});
    }

    @media (max-width: 820px) {
        button {
            pointer-events: none;
        }

        .content {
            max-width: 90%;

            .top {
                .left {
                    span:first-child {
                        margin-left: 0px;
                    }

                    span:nth-child(2) {
                        margin-left: 18px;
                    }
                }

                .right {
                    span:first-child {
                    }
                }
            }

            .listBox {
                .list {
                    pointer-events: none;
                    .num {
                        margin-left: 5px;
                    }

                    .title {
                        padding-left: 30px;
                    }
                }
            }
        }
    }

    @media (max-width: 550px) {
        padding-bottom: 100px;

        h1 {
            font-size: 26px;
        }

        p {
            font-size: 16px;
        }

        .content {
            max-width: 90%;

            .top {
                display: none;
            }
        }

        .listBox {
            width: 100%;

            .list {
                width: 100%;
                height: auto;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                position: relative;

                .num {
                    display: none;
                }

                .title {
                    flex: none;
                    width: 100%;
                    text-align: left;
                    position: absolute;
                    top: 10px;
                    left: -20px;
                }

                .rightBox {
                    width: 100%;
                    position: absolute;
                    bottom: 10px;
                    left: 10px;

                    .views {
                        .viewsText {
                            display: inline;
                            color: rgba(171, 171, 171, 1);
                        }

                        .viewsText:first-child {
                            margin-left: -60px;
                            margin-right: 10px;
                        }
                    }
                }

                .date,
                .views {
                    flex: none;
                    display: inline;
                    font-size: 14px;
                    color: rgba(171, 171, 171, 1);
                }

                .date {
                    flex: none;
                    display: inline;
                }

                .views {
                    flex: none;
                    display: inline;
                }
            }
        }
    }
`;
