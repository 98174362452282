import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import square from "../../assets/images/info/img-square.svg";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { Helmet } from "react-helmet";
import { MainContent } from "../main/Main";
import { formatDate } from "../../common/util-function";
import DOMPurify from "isomorphic-dompurify";
import "react-quill/dist/quill.snow.css";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Titles from "../../components/Title";
import Dividers from "../../components/Dividers";
import { applyTypography } from "../../styles/mixins";

const NoticePage = () => {
    const { postId } = useParams();
    const [post, setPost] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const fromMain = location.state?.fromMain || false;
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [mains, setMains] = useState<MainContent>();

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPost = async () => {
            try {
                const response = await axios.get(`${APIURL}/board/${postId}`);
                const mains = await axios.get(`${APIURL}/manage/main`);
                //console.log(response.data);
                setPost(response.data.data);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, [postId]);

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-${mains?.textEn.title}`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-${mains?.textKo.title}`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? post.titleEn : post.titleKo}</Title>
                    <List>
                        <div className="box">
                            <div className="date">{formatDate(post.createdAt)}</div>
                            <div className="seperate">|</div>
                            <div className="views">
                                <div className="viewsText">{activeLanguage === "en" ? "Count" : "조회수"}</div>
                                <div>:</div>
                                <div>{post.views}</div>
                            </div>
                        </div>
                    </List>
                    <Divider />
                    <Contents>
                        <p
                            className="view ql-editor"
                            dangerouslySetInnerHTML={{
                                __html:
                                    activeLanguage === "en"
                                        ? DOMPurify.sanitize(post.contentEn)
                                        : DOMPurify.sanitize(post.contentKo),
                            }}
                        ></p>
                    </Contents>
                    <Divider />
                    <Link to="/notice">
                        <Button
                            onClick={() => {
                                if (fromMain) {
                                    navigate("/");
                                } else {
                                    navigate(-1);
                                }
                            }}
                        >
                            {activeLanguage === "en" ? "Back" : "돌아가기"}
                        </Button>
                    </Link>
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default NoticePage;

const Wrap = styled(Wraps)``;

const PaddingWrap = styled(PaddingWraps)`
    @media (min-width: 1440px) {
        max-width: 1200px;
    }
`;

const Title = styled(Titles)`
    @media (min-width: 768px) {
        line-height: 60px;
    }
`;

const Divider = styled(Dividers)``;

const List = styled.div`
    .box {
        display: flex;
        ${applyTypography("secondary", 1)};
        margin-top: 12px;
        gap: 5px;
    }
    .views {
        display: flex;
        gap: 2px;
    }
`;

const Contents = styled.div`
    position: relative;

    .ql-editor {
        padding: 0px;

        img {
            width: 100%;
        }
    }

    p {
        line-height: 26px;
        white-space: pre-wrap;
    }
`;

const Button = styled.button`
    ${applyTypography("secondary", 2)};
    width: 188px;
    height: 50px;
    background: black;
    color: #f9f8f8;
    cursor: pointer;
    margin-bottom: 100px;

    @media (min-width: 768px) {
        margin-bottom: 200px;
    }

    &:hover {
        background: #fed800;
        border: none;
        color: black;
        transition: 0.3s;
    }
`;
