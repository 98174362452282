import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { APIURL } from "../constants";
import { Link } from "react-router-dom";
import top from "../assets/images/main/pop-top.svg";
import footer from "../assets/images/main/pop-footer.svg";
import { applyTypography } from "../styles/mixins";

interface Post {
    id: number;
    titleKo: string;
    contentKo: string;
    isOpen: boolean;
    isHidden?: boolean;
    link: string;
    type: string;
    imagePath: string;
    titleEn: string;
    contentEn: string;
}
type PopupProps = {
    page?: string;
};

export default function Popup({ page }: PopupProps) {
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [posts, setPosts] = useState<Post[]>([]);
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    useEffect(() => {
        console.log(window.location.pathname.split("/"));
        const path = window.location.pathname.split("/");
        setActiveLanguage(path[1]);
        console.log(page);
        const fetchPosts = async () => {
            try {
                let response;
                if (page === "attend") {
                    response = await axios.get(`${APIURL}/manage/popup/page`);
                } else {
                    response = await axios.get(`${APIURL}/manage/popup/list`);
                }
                //console.log(response.data);

                const updatedPosts = response.data.data.map((post: Post) => {
                    const hidePopupDate = localStorage.getItem(`hidePopupDate_${post.id}`);
                    const isHidden = hidePopupDate && new Date(hidePopupDate) > new Date();
                    return {
                        ...post,
                        isHidden: isHidden, // 오늘 하루 동안 보지 않기 설정을 적용
                    };
                });
                setPosts(updatedPosts);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPosts();
    }, [page]);

    useEffect(() => {
        posts.forEach((post) => {
            const hidePopupDate = localStorage.getItem(`hidePopupDate_${post.id}`);
            if (hidePopupDate && new Date(hidePopupDate) > new Date()) {
                handleClose(post.id); // 자동으로 숨김 처리
            }
        });
    }, []);

    const handleClose = (postId: number, hideForDay: boolean = false) => {
        const updatedPosts = posts.map((post) => {
            if (post.id === postId) {
                if (hideForDay) {
                    // 하루 동안 보지 않기
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    localStorage.setItem(`hidePopupDate_${postId}`, tomorrow.toDateString());
                }
                return { ...post, isHidden: true };
            }
            return post;
        });
        setPosts(updatedPosts);
    };

    const allPopupsHidden = posts.every((post) => post.isHidden);
    //console.log(allPopupsHidden);

    const onLink = (link: string) => {
        window.open(link, "_blank");
    };
    //console.log(posts);
    return (
        <>
            {!allPopupsHidden && (
                <Container>
                    {posts.map(
                        (post) =>
                            !post.isHidden && (
                                <PopupBox key={post.id}>
                                    <StyleLink to={post.link} target="_blank">
                                        <ContentBox>
                                            <img src={top} alt="top" />
                                            {(post.type === "Image" || post.type === "TextImage") && (
                                                <img
                                                    className="mainImage"
                                                    src={`${APIURL}/${post.imagePath}`}
                                                    alt={post.titleKo}
                                                />
                                            )}
                                            {(post.type === "Text" || post.type === "TextImage") && (
                                                <TextBox>
                                                    <Title>
                                                        {activeLanguage === "en" ? post.titleEn : post.titleKo}
                                                    </Title>
                                                    <Content>
                                                        {activeLanguage === "en" ? post.contentEn : post.contentKo}
                                                    </Content>
                                                </TextBox>
                                            )}
                                            <img src={footer} alt="footer" />
                                        </ContentBox>
                                    </StyleLink>
                                    <Bottom>
                                        <Button onClick={() => handleClose(post.id, true)}>
                                            {activeLanguage === "en"
                                                ? "Don't see it for today"
                                                : "오늘 하루 동안 보지 않기"}
                                        </Button>
                                        <Close onClick={() => handleClose(post.id)}>
                                            {activeLanguage === "en" ? "Close" : "닫기"}
                                        </Close>
                                    </Bottom>
                                </PopupBox>
                            )
                    )}
                </Container>
            )}
        </>
    );
}

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    @media (min-width: 767px) {
        padding: 150px 0px;
        flex-wrap: wrap;
        align-items: start;
    }
`;

const PopupBox = styled.div`
    display: flex;
    flex-direction: column;
    background: #036eb8;
    /* backdrop-filter: blur(10.399999618530273px);
    -webkit-backdrop-filter: blur(10.4px); */
    /* border: solid 4px #000;
    box-sizing: border-box; */
    text-align: left;
    position: absolute;
    max-width: 360px;

    @media (min-width: 767px) {
        max-width: 500px;
        min-width: 500px;
        margin: 0px 5px;
        position: relative;
    }
`;

const TextBox = styled.div`
    padding: 24px;
    color: white;
`;

const Bottom = styled.div`
    position: relative;
    display: flex;
    background: black;
    color: white;
    height: 42px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
`;

const Button = styled.div`
    border: 0;
    cursor: pointer;
    font-size: 12px;
    /* position: absolute; */
`;

const Close = styled.div`
    color: white;
    font-size: 12px;
    cursor: pointer;
`;

const Title = styled.div`
    ${applyTypography("primary", 3)};
`;

const Content = styled.div`
    white-space: pre-wrap;
    ${applyTypography("secondary", 2)};
    margin-top: 28px;
    line-height: 22.4px;
`;

const StyleLink = styled(Link)`
    border-top: solid 4px #000;
    border-left: solid 4px #000;
    border-right: solid 4px #000;
    box-sizing: border-box;
    padding: 10px;
`;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    box-sizing: border-box;
    border-left: solid 4px #000;
    border-right: solid 4px #000;
    .mainImage {
        padding: 10px;
    }
`;
