import styled from "styled-components";

const PaddingWraps = styled.div`
    width: 92%;
    height: 100%;
    position: relative;
    margin-top: 84px; /* top + 패턴 */

    @media (min-width: 768px) {
        margin-top: 108px;
    }

    @media (min-width: 1440px) {
        /* width: 80%; */
        max-width: 1440px;
    }
`;

export default PaddingWraps;
