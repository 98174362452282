import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import square from "../../assets/images/info/img-square.svg";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { Helmet } from "react-helmet";
import { MainContent } from "../main/Main";
import { formatDate } from "../../common/util-function";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Titles from "../../components/Title";
import Dividers from "../../components/Dividers";
import { applyTypography } from "../../styles/mixins";
import gofaq from "../../assets/images/info/gofaq.svg";

type Notice = {
    id: number;
    titleKo: string;
    titleEn: string;
    date: string;
    views: number;
    createdAt: string;
};

const Notice = () => {
    const [posts, setPosts] = useState<Notice[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRange, setPageRange] = useState([1, 5]);

    const [itemsPerPage, setItemsPerPage] = useState(20); // 기본값을 PC용으로 설정

    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [mains, setMains] = useState<MainContent>();

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = posts.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(posts.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    };

    const goToNextPage = () => {
        setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
    };
    const handlePageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    //높이확인
    const containerRef = useRef<HTMLDivElement>(null);
    const [isLargeHeight, setIsLargeHeight] = useState(false);
    useEffect(() => {
        // 요소의 높이를 확인
        const checkHeight = () => {
            if (containerRef.current) {
                const height = containerRef.current.clientHeight;
                setIsLargeHeight(height > 700); // 높이가 700px 이상일 경우 true
            }
        };

        checkHeight(); // 처음 로딩 시 높이 확인
        window.addEventListener("resize", checkHeight); // 창 크기 조정 시에도 확인

        return () => window.removeEventListener("resize", checkHeight); // 리스너 해제
    }, []);
    useEffect(() => {
        const updateItemsPerPage = () => {
            if (window.innerWidth < 768) {
                setItemsPerPage(5); // 모바일 화면에서는 5개
            } else {
                setItemsPerPage(20); // PC 화면에서는 20개
            }
        };

        // 초기 실행
        updateItemsPerPage();

        // 화면 크기 변화 감지
        window.addEventListener("resize", updateItemsPerPage);

        // 이벤트 리스너 해제
        return () => window.removeEventListener("resize", updateItemsPerPage);
    }, []);
    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${APIURL}/board`);
                //console.log(response.data);
                // const sortedPosts = response.data.data.sort((a: Notice, b: Notice) => {
                //     return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                // });
                const mains = await axios.get(`${APIURL}/manage/main`);

                setPosts(response.data.data);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, []);
    const renderPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }

        return (
            <Pagination>
                <button className="btn" onClick={goToPreviousPage} disabled={currentPage === 1}>
                    {"◀︎"}
                </button>
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageClick(number)}
                        className={currentPage === number ? "active" : ""}
                    >
                        {number}
                    </button>
                ))}
                <button className="btn" onClick={goToNextPage} disabled={currentPage === totalPages}>
                    {"▶"}
                </button>
            </Pagination>
        );
    };

    const formatNumberWithCommas = (number: number): string => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-${mains?.textEn.title}`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-${mains?.textKo.title}`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <TitleBox>
                        <Title>{activeLanguage === "en" ? mains?.textEn.title : mains?.textKo.title}</Title>
                        <FaqLink to={`/${activeLanguage}/faq`}>
                            {activeLanguage === "en" ? "Go to FAQ" : "FAQ 바로가기"}
                        </FaqLink>
                        <FaqLinkImg to={`/${activeLanguage}/faq`}>
                            <img src={gofaq} alt="gofaq" />
                        </FaqLinkImg>
                    </TitleBox>
                    <Container ref={containerRef} isLargeHeight={isLargeHeight}>
                        <ListBox>
                            <Divider />
                            {currentItems.map((post, index) => (
                                <Link to={`/${activeLanguage}/notice/${post.id}`} key={post.id}>
                                    <List>
                                        <div className="num">{currentItems.length - index}</div>
                                        <div className="title">
                                            {activeLanguage === "en" ? post.titleEn : post.titleKo}
                                        </div>
                                        <div className="box">
                                            <div className="date">{formatDate(post.createdAt)}</div>
                                            <div className="seperate">|</div>
                                            <div className="views">
                                                <div className="viewsText">
                                                    {activeLanguage === "en" ? "Count" : "조회수"}
                                                </div>
                                                <div>:</div>
                                                <div>{post.views}</div>
                                            </div>
                                        </div>
                                    </List>
                                </Link>
                            ))}
                        </ListBox>
                        <TableBox>
                            <Thead>
                                <Tr>
                                    <th>{activeLanguage === "en" ? "No" : "순서"}</th>
                                    <th>{activeLanguage === "en" ? "Title" : "제목"}</th>
                                    <th>{activeLanguage === "en" ? "Date" : "등록일"}</th>
                                    <th>{activeLanguage === "en" ? "Views" : "조회수"}</th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentItems.map((post, index) => (
                                    <Link to={`/${activeLanguage}/notice/${post.id}`}>
                                        <Tr key={post.id}>
                                            <td>{currentItems.length - index}</td>
                                            <td>{activeLanguage === "en" ? post.titleEn : post.titleKo}</td>
                                            <td>{formatDate(post.createdAt)}</td>
                                            <td>{formatNumberWithCommas(post.views)}</td>
                                        </Tr>
                                    </Link>
                                ))}
                            </Tbody>
                        </TableBox>
                        {renderPagination()}
                    </Container>
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default Notice;

const Wrap = styled(Wraps)``;

const PaddingWrap = styled(PaddingWraps)`
    @media (min-width: 1440px) {
        max-width: 1200px;
    }
`;

const Title = styled(Titles)`
    margin-top: 0px;
`;

const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 29px;
    position: relative;
    @media (min-width: 768px) {
        margin-top: 36px;
    }
`;
const FaqLink = styled(Link)`
    ${applyTypography("secondary", 3)};
    padding: 18px 22px;
    background: #000;
    color: #fff;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #c30f23;
    }
    @media (min-width: 1440px) {
        display: none;
    }
`;
const FaqLinkImg = styled(Link)`
    display: none;
    @media (min-width: 1440px) {
        display: block;
        position: absolute;
        right: -180px;
        top: 80px;
    }
`;

const Divider = styled(Dividers)`
    margin-top: 21px;
    @media (min-width: 768px) {
        margin-top: 40px;
    }
`;

const Container = styled.div<{ isLargeHeight: boolean }>`
    margin-bottom: ${({ isLargeHeight }) => (isLargeHeight ? "100px" : "500px")};
`;
const ListBox = styled.div`
    display: flex;
    flex-direction: column;
    a {
        margin-bottom: 16px;
    }
    @media (min-width: 768px) {
        display: none;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 4px solid #ebebeb;

    .num {
        display: none;
    }
    .title {
        ${applyTypography("secondary", 1)};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .box {
        display: flex;
        ${applyTypography("secondary", 1)};
        color: #ababab;
        margin-top: 4px;
        margin-bottom: 16px;
        gap: 5px;
    }
    .views {
        display: flex;
        gap: 2px;
    }
`;

const TableBox = styled.table`
    display: none;
    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Thead = styled.thead`
    border-top: 4px solid black;
    border-bottom: 4px solid black;
    box-sizing: border-box;
    margin-top: 36px;
`;

const Tbody = styled.tbody`
    tr {
        border-bottom: 4px solid #ebebeb;
        transition: background-color 0.3s ease, border-bottom-color 0.3s ease;

        &:hover {
            background-color: #fed800;
            border-bottom: 4px solid black;
            cursor: pointer;
        }
    }
`;

const Tr = styled.tr`
    display: flex;
    justify-content: space-between;
    ${applyTypography("secondary", 1)};
    padding: 20px 0px;
    a {
        color: black;
    }

    th:first-child,
    td:first-child {
        width: 10%;
        text-align: center;
    }
    th:nth-child(2),
    td:nth-child(2) {
        width: 70%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 30px;
    }
    th:nth-child(3),
    td:nth-child(3) {
        width: 20%;
        text-align: left;
    }
    th:nth-child(4),
    td:nth-child(4) {
        width: 10%;
        text-align: center;
    }
`;
const Pagination = styled.div`
    margin-top: 28px;
    display: flex;
    justify-content: center;
    gap: 10px;
    .active {
        color: black;
    }

    button {
        color: #ababab;
        background: none;
        border: none;
        cursor: pointer;

        &:disabled {
            border: solid 4px #ababab;
            color: #ababab;
            background: none;

            &:hover {
                border: solid 4px #ababab;
                background-color: #ffffff;
                color: #ababab;
                cursor: not-allowed;
            }
        }
    }
    .btn {
        border: solid 4px black;
        color: black;
        font-weight: bold;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            transition: background-color 0.3s ease;
            background-color: #000000;
            color: #ffffff;
        }
    }
`;
