// src/styles/typography.js

const typography = {
    fontStyles: {
        primary: {
            fontFamily: `'GmarketSansBold', sans-serif`,
            fontSizes: {
                web: ["56px", "44px", "36px", "28px", "22px"], // 웹용 크기
                mobile: ["30px", "26px", "20px", "18px", "16px"], // 모바일용 크기
            },
            fontWeights: {
                medium: 500,
            },
        },
        secondary: {
            fontFamily: `'PretendardMedium', sans-serif`,
            fontSizes: {
                web: ["18px", "16px", "14px", "16px"], // 웹용 크기
                mobile: ["16px", "14px", "12px", "14px"], // 모바일용 크기
            },
            fontWeights: {
                medium: 500,
            },
        },
    },
};

export default typography;
