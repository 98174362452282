import styled from "styled-components";

const Wraps = styled.div`
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
`;

export default Wraps;
