import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import Router from "./shared/Router";
import "./styles/fonts.css"; // 폰트 스타일 불러오기

function App() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <CookiesProvider>
                <Router />
            </CookiesProvider>
        </QueryClientProvider>
    );
}

export default App;
