import styled from "styled-components";
import { applyTypography } from "../../styles/mixins";

interface NaviWrapProps {
    $isSeoulConOpen?: boolean;
    $isProgramOpen?: boolean;
    $isInfoOpen?: boolean;
    $isScrolled: boolean;
}

export const Dim = styled.div`
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;

    &.visible {
        display: block;
    }
`;

export const NaviWrap = styled.div<NaviWrapProps>`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    .separate {
        height: 20px;
        background: #ffffff;
        position: absolute;
        top: 60px;
        width: 100%;
    }

    /* .logo {
        width: 498px;
        @media (max-width: 768px) {
            width: 277px;
        }
    } */

    .active {
        opacity: 1 !important;
    }

    .background-blur {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .padding {
        position: absolute;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #000000;

        .mobile-menu-toggle {
            display: none;
        }
    }
    .padding-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .left {
        position: relative;
        z-index: 900;

        img {
            display: flex;
            align-items: center;
        }
    }

    .left img,
    .right button {
        opacity: 1;
    }

    .right {
        display: flex;
        align-items: center;
        z-index: 999;
        margin-left: 40px;
        text-decoration: none;

        .toggle {
            display: none;
            z-index: 1000;
        }

        .menu-container {
            position: relative;
            display: inline-block;
            text-decoration: none;
        }

        button {
            background: none;
            border: none;
            padding: 0 30px;
            cursor: pointer;
            display: flex;
            align-items: center;
            ${applyTypography("secondary", 3)};
            position: relative;
            color: #f9f8f8;
            height: 60px;
            text-decoration: none;

            &:nth-last-child(4) {
                margin-right: 50px;
            }
        }

        button span {
            display: inline-block;
            position: relative;
        }

        button span::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -2px;
            height: 1px;
            width: 0;
            background-color: white;
            text-decoration: none;
        }

        .menu-container:hover button span::after {
            width: 100%;
            bottom: -3px;
        }

        button.active span::after {
            width: 100%;
            bottom: -3px;
        }
        .button-lang {
            display: none;
        }

        hr {
            height: 10px;
            margin: 0 15px;
            background: white;
            border: none;
            width: 1px;
            opacity: 40%;
        }

        .ko,
        .en {
            color: white;
            opacity: 40%;
            padding: 0px;
        }
        .ko {
            margin-left: 30px;
        }
        .en {
            margin-right: 30px;
        }

        .submenu {
            display: flex;
            flex-direction: column;
            position: absolute;
            background: #ffffff;
            border: solid 4px #0f0b0a;
            left: 0%;
            top: 69%;
            width: 237px;

            a {
                display: block;
                width: 100%;

                button {
                    width: 100%;
                    padding: 0px 15px;
                    text-align: left;
                    height: 58px;
                    border: none;
                    ${applyTypography("secondary", 3)};
                    color: #000000;

                    &:hover {
                        transition: 0.2s;
                        background: #000000;
                        color: #ffffff;
                    }
                }
                /* 
                &:not(:last-child) button {
                    border-bottom: 1px solid #565656;
                } */
            }
        }
    }
    .lang {
        display: flex;
        align-items: center;

        button {
            background: none;
            border: none;
            ${applyTypography("secondary", 3)};
        }
        hr {
            height: 10px;
            margin: 0 15px;
            background: white;
            border: none;
            width: 1px;
            opacity: 40%;
        }

        .ko,
        .en {
            color: #f9f8f8;
            opacity: 40%;
            padding: 0px;
            cursor: pointer;
        }
        .ko {
            margin-left: 30px;
        }
        .en {
            margin-right: 32px;
        }
    }

    @media (max-width: 1440px) {
        .lang {
            display: none;
        }
        .button-lang {
            display: flex !important;
            position: absolute;
            top: 13px;
            align-items: center;
        }
        .padding {
            .mobile-menu-toggle {
                display: block;
                z-index: 1000;
                background: none;
                border: none;
                cursor: pointer;
                position: absolute;
                right: 32px;
            }
        }

        .right {
            position: absolute;
            top: 0;
            right: -100%;
            width: 385px;
            height: 100vh;
            background-color: #ffffff;
            flex-direction: column;
            align-items: flex-start;
            padding: 2rem;
            border: 4px solid #000000;
            box-sizing: border-box;

            &.open {
                right: 0;
            }

            button,
            hr {
                padding: 0;
            }

            .ko {
                margin-left: 0px;
            }

            .toggle {
                display: flex;
            }

            .menu-container {
                width: 100%;

                button {
                    height: 80px;
                    display: flex;
                    width: 100%;
                    text-align: left;
                    justify-content: space-between;
                    padding: 0;
                    color: #000000;

                    .toggle {
                        width: 20px;
                    }
                }

                .submenu {
                    width: 100%;
                    height: auto;
                    position: static;
                    flex-direction: column;
                    justify-content: start;
                    padding: 0;
                    display: flex;
                    max-width: 100%;
                    padding: 15px 0;
                    border: none;

                    a {
                        width: 100%;

                        button {
                            height: auto;
                            color: #000000;
                            padding: 15px 0;
                            pointer-events: none;
                            width: 100%;
                            border: none;
                        }
                    }
                }

                .col {
                    a {
                        width: 100%;
                    }
                }

                .menuBtn,
                .menuBtn2,
                .menuBtn3,
                .menuBtn4 {
                    border-bottom: 4px solid #000000;
                }
            }
            hr {
                background: #ebebeb;
                opacity: 1;
            }
            .ko,
            .en {
                color: #000000;
            }

            /* .ko,
            hr,
            .en {
                position: absolute;
                top: -10px;
                display: inline-block;
                vertical-align: middle;
                display: flex;
                flex-direction: row;
                color: #000000;
            }

            hr {
                position: absolute;
                top: 25px;
                left: 60px;
            }

            .en {
                position: absolute;
                left: 95px;
            } */

            .menu-container:first-child {
                margin-top: 50px;
            }
        }
    }

    @media (max-width: 768px) {
        .separate {
            height: 16px;
            top: 40px;
        }
        .left {
            img {
                height: 40px;
            }
        }
        .padding {
            height: 40px;
            .mobile-menu-toggle {
                right: 7px;
                top: 7px;
            }
        }
        .right {
            width: 75%;
        }
    }
`;
