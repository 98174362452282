import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import pentagon from "../../assets/images/info/img-pentagon.svg";
import { useInView } from "react-intersection-observer";
import { useEffect, useRef, useState } from "react";
import en from "../../assets/images/info/img-foreigner-default.png";
import ko from "../../assets/images/info/img-korean-default.png";
import bg from "../../assets/images/info/bg-attend.jpg";
import koHover from "../../assets/images/info/img-korean-hover-yellow.png";
import enHover from "../../assets/images/info/img-foreigner-hover-yellow.png";
import { Helmet } from "react-helmet";
import { fadeInUpAnimation } from "../info/GuideMap";
import axios from "axios";
import { APIURL } from "../../constants";
import { MainContent } from "../main/Main";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Dividers from "../../components/Dividers";
import { applyTypography } from "../../styles/mixins";
import company from "../../assets/images/info/ico-company.svg";
import influencer from "../../assets/images/info/ico-influencer.svg";
import citizen from "../../assets/images/info/ico-citizen.svg";
import press from "../../assets/images/info/ico-press.svg";
import { Link } from "react-router-dom";
import Titles from "../../components/Title";
import Popup from "../../components/Popup";

// Types

const statusColors = {
    Pending: "#BFFF6A",
    Soldout: "#FF0E14",
    Unable: "#3a3a3a",
    Sale: "#9339ff",
    None: "",
    NoneLink: "",
};

const statusFontColors = {
    Pending: "black",
    Soldout: "white",
    Unable: "white",
    Sale: "white",
    None: "",
    NoneLink: "",
};

const statusTextColors = {
    Pending: "white",
    Soldout: "white",
    Unable: "#565656",
    Sale: "white",
    None: "",
    NoneLink: "",
};

// Define status translations
const statusTranslations = {
    Pending: { en: "Open Later", ko: "오픈 예정" },
    Soldout: { en: "Soldout", ko: "매진 완료" },
    Unable: { en: "Unable", ko: "신청 불가" },
    Sale: { en: "On Sale", ko: "신청 가능" },
    None: { en: "", ko: "" },
    NoneLink: { en: "", ko: "" },
};

const Attend = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [isKoHovered, setIsKoHovered] = useState(false);
    const [isEnHovered, setIsEnHovered] = useState(false);
    const [festivals, setFestival] = useState([]);
    const [conferences, setConference] = useState([]);
    const [contents, setContent] = useState([]);
    const [entertainments, setEntertainment] = useState([]);
    const [wintafestas, setWintafesta] = useState([]);
    const [mains, setMains] = useState<MainContent>();

    const { ref: appRef, inView: appView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });
    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPosts = async () => {
            try {
                const festival = await axios.get(`${APIURL}/program/festival`);
                const conference = await axios.get(`${APIURL}/program/conference`);
                const content = await axios.get(`${APIURL}/program/content`);
                const entertainment = await axios.get(`${APIURL}/program/entertainment`);
                const wintafesta = await axios.get(`${APIURL}/program/wintafesta`);
                const mains = await axios.get(`${APIURL}/manage/main`);

                setFestival(festival.data.data);
                setConference(conference.data.data);
                setContent(content.data.data);
                setEntertainment(entertainment.data.data);
                setWintafesta(wintafesta.data.data);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, []);

    const [isCompany, setCompany] = useState(false);
    const [isInflu, setInflu] = useState(false);
    const [isCitizen, setCitizen] = useState(false);
    const [isPress, setPress] = useState(false);

    //높이확인
    // const containerRef = useRef<HTMLDivElement>(null);
    // const [isLargeHeight, setIsLargeHeight] = useState(false);
    const [marginBottom, setMarginBottom] = useState("50px");

    useEffect(() => {
        const updateMarginBottom = () => {
            const screenHeight = window.innerHeight;

            // 세로 크기에 따라 margin-bottom 설정
            if (screenHeight >= 1200) {
                setMarginBottom("400px");
            } else if (screenHeight >= 700) {
                setMarginBottom("100px");
            } else {
                setMarginBottom("50px");
            }
        };

        // 초기 높이 설정
        updateMarginBottom();

        // 창 크기 변경 시 이벤트 추가
        window.addEventListener("resize", updateMarginBottom);

        return () => {
            // 이벤트 제거
            window.removeEventListener("resize", updateMarginBottom);
        };
    }, []);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Attend`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-신청하기`}</title>
                </Helmet>
            )}
            {/* <Popup page={"attend"} /> */}
            <Navibar />
            <Wrap marginBottom={marginBottom}>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? "Attend" : "신청하기"}</Title>
                    <Divider />
                    <Container>
                        {/* <Content
                            isHovered={isCompany}
                            color={"#c30d23"}
                            font={"#ffffff"}
                            onMouseEnter={() => setCompany(true)} // hover 시 상태 변경
                            onMouseLeave={() => setCompany(false)} // hover 해제 시 상태 변경
                        >
                            <img className="company" src={company} alt="company" />
                            <p>{activeLanguage === "en" ? "Business" : "기업"}</p>
                            <StyledLink to={`/${activeLanguage}/attend`}>
                                {activeLanguage === "en" ? "Go" : "서울콘 신청"}
                            </StyledLink>
                        </Content> */}
                        <Content
                            isHovered={isCitizen}
                            color={"#036eb8"}
                            font={"#ffffff"}
                            onMouseEnter={() => setCitizen(true)} // hover 시 상태 변경
                            onMouseLeave={() => setCitizen(false)} // hover 해제 시 상태 변경
                        >
                            <img className="citizen" src={citizen} alt="citizen" />
                            <p>{activeLanguage === "en" ? "General" : "일반인"}</p>
                            {activeLanguage === "en" ? (
                                <StyledLink
                                    to={`https://app.catchsecu.com/projects/2ea585561ad0f38/form`}
                                    target="_blank"
                                >
                                    {"Go"}
                                </StyledLink>
                            ) : (
                                <StyledLink
                                    to={`https://app.catchsecu.com/projects/c4ef165cf868e10/form`}
                                    target="_blank"
                                >
                                    {"서울콘 신청"}
                                </StyledLink>
                            )}
                        </Content>
                        <Content
                            isHovered={isInflu}
                            color={"#fed800"}
                            font={"#000000"}
                            onMouseEnter={() => setInflu(true)} // hover 시 상태 변경
                            onMouseLeave={() => setInflu(false)} // hover 해제 시 상태 변경
                        >
                            <img className="influencer" src={influencer} alt="influencer" />
                            <p>{activeLanguage === "en" ? "Creator" : "크리에이터"}</p>

                            {activeLanguage === "en" ? (
                                <StyledLink
                                    to={`https://app.catchsecu.com/projects/9e2c9fe0d7835bd/form`}
                                    target="_blank"
                                >
                                    {"Go"}
                                </StyledLink>
                            ) : (
                                <StyledLink
                                    to={`https://app.catchsecu.com/projects/c8b2e740e8d9d71/form`}
                                    target="_blank"
                                >
                                    {"서울콘 신청"}
                                </StyledLink>
                            )}
                        </Content>

                        <Content
                            isHovered={isPress}
                            color={"#006934"}
                            font={"#ffffff"}
                            onMouseEnter={() => setPress(true)} // hover 시 상태 변경
                            onMouseLeave={() => setPress(false)} // hover 해제 시 상태 변경
                        >
                            <img className="press" src={press} alt="press" />
                            <p>{activeLanguage === "en" ? "Press" : "언론"}</p>
                            <StyledLink to={``}>{activeLanguage === "en" ? "Go" : "서울콘 신청"}</StyledLink>
                        </Content>
                    </Container>
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default Attend;

const Wrap = styled(Wraps)<{ marginBottom: string }>`
    margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const PaddingWrap = styled(PaddingWraps)`
    @media (min-width: 1440px) {
        max-width: 1200px;
    }
`;

const Title = styled(Titles)``;

const Divider = styled(Dividers)``;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 100px;

    /* @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
    } */
    @media (min-width: 1440px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const StyledLink = styled(Link)`
    background-color: black;
    ${applyTypography("secondary", 3)};
    color: #ffffff;
    transition: background-color 0.3s ease;
    margin-top: 24px;
    padding: 18px 70px;
`;

const Content = styled.div<{ isHovered?: boolean; color?: string; font?: string }>`
    border: 4px black solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    transition: background-color 0.3s ease;
    background-color: ${(props) => props.isHovered && props.color};
    cursor: pointer;

    img {
        width: 100px;
        height: 100px;
    }
    p {
        ${applyTypography("primary", 3)};
        margin-top: 3px;
        color: ${(props) => props.isHovered && props.font};
    }

    /* @media (min-width: 768px) {
        width: 40.6%;
    } */
    @media (min-width: 1440px) {
        width: 27.5%;
    }
`;
