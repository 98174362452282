import circle from "../../assets/images/seoulcon/img-round.svg";
import brandImg from "../../assets/images/seoulcon/img-brand.jpg";
import Navibar from "../../components/navigation/Navibar";
import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import appImg1 from "../../assets/images/seoulcon/img-application1.jpg";
import appImg2 from "../../assets/images/seoulcon/img-application2.jpg";
import { useInView } from "react-intersection-observer";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { Helmet } from "react-helmet";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import { applyTypography } from "../../styles/mixins";
import Dividers from "../../components/Dividers";
import bi1 from "../../assets/images/seoulcon/img-seoulcon.svg";
import bi2 from "../../assets/images/seoulcon/bi2.svg";
import author from "../../assets/images/seoulcon/img-author.png";
import smile from "../../assets/images/program/char-smile.png";
import run from "../../assets/images/program/char-run.png";
import charStay from "../../assets/images/seoulcon/char3.svg";
import art1 from "../../assets/images/seoulcon/art1.png";
import art2 from "../../assets/images/seoulcon/art2.png";
import art3 from "../../assets/images/seoulcon/art3.png";
import art4 from "../../assets/images/seoulcon/art4.png";
import artm from "../../assets/images/seoulcon/img-artwork-m.svg";
import artpc from "../../assets/images/seoulcon/img-artwork-pc.svg";
import seoulImage from "../../assets/images/seoulcon/seoulcon.svg";
import Titles from "../../components/Title";

const Bi = () => {
    const [titleKo, setTitleKo] = useState("");
    const [titleEn, setTitleEn] = useState("");
    const [contentKo, setContentKo] = useState("");
    const [contentEn, setContentEn] = useState("");
    const [type1Ko, setType1Ko] = useState<any>({});
    const [type2Ko, setType2Ko] = useState<any>({});
    const [type1En, setType1En] = useState<any>({});
    const [type2En, setType2En] = useState<any>({});
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [previewUrl, setPreviewUrl] = useState("");
    const [previews, setPreviews] = useState<any>([]);

    const imgRef = useRef<HTMLImageElement | null>(null); // img 요소 참조를 위한 ref
    const [animateImg, setAnimateImg] = useState<boolean>(false); // img 애니메이션 상태

    const { ref: biRef, inView: biView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });

    const { ref: appRef, inView: appView } = useInView({
        triggerOnce: true,
        threshold: 0.01,
        initialInView: false,
    });
    useEffect(() => {
        const fetchPost = async () => {
            setActiveLanguage(sessionStorage.getItem("lang") || "ko");

            try {
                const response = await axios.get(`${APIURL}/manage/bi`);
                //console.log(response.data.data);
                const result = response.data.data;

                setTitleKo(result.titleKo);
                setTitleEn(result.titleEn);
                setType1Ko({
                    brand: result.type1Ko.brand,
                    newBrand: "2024 브랜드 심볼",
                    title: result.type1Ko.title,
                    newTitle: "2024 SEOULCON X GRAFFLEX",
                    content: result.type1Ko.content,
                    newContent: `한국 전통가옥의 기와와 단청 컬러를 기반으로 그라플렉스만의 컨셉이 녹아든 패턴을 제작하였습니다.
전통적인 분위기를 현대적인 감각으로 표현하여 재해석한 작업으로 다양한 알파벳 텍스트를
디자인하였고 서울콘 로고를 비롯하여 다양한 글씨에 사용될 수 있습니다.`,
                    author: "GRAFFLEX 작가 소개",
                    authorDesc: `일러스트, 회화, 설치, 아트 토이 등 장르의 경계를 넘나드는 작업으로 다양한 작품에 재미와 즐거움을 담아내며 자신만의 예술 세상을 만들어가는 아티스트입니다. 이번 24년 서울콘의 BI 리뉴얼 총 감독을 맡아
기존 서울콘의 로고와 심볼을 활용하여 독창적인 컨셉의 그래픽 요소들을 작업하였습니다.`,
                });
                setType1En({
                    brand: result.type1En.brand,
                    newBrand: "2024 Brand Symbol",
                    title: result.type1En.title,
                    newTitle: "2024 SEOULCON X GRAFFLEX",
                    content: result.type1En.content,
                    newContent: `We created a pattern that incorporates Grafflex's unique concept based on the roof tiles and Dancheong colors of traditional Korean houses.
This work reinterpreted the traditional atmosphere from a modern sensibility so that designed various alphabet texts, which can be used for various letters, including the SEOULCon logo.
`,
                    author: `Who is ‘GRAFFELX’ ?`,
                    authorDesc: `This artist creates his own art world by incorporating fun and enjoyment into his diverse works, crossing boundaries of genres such as illustration, painting, installation, and art toys.
He was in charge of the BI renewal of the 2024 SEOULCon, and worked on graphic elements with unique concepts using the existing logo and symbol of SEOULCon.
`,
                });
                setType2Ko({
                    brand: "브랜드 캐릭터",
                    content: `GRAFFLEX의 캐릭터인 BOLD를 이용하여 다양하게 노출하고자 작업하였습니다.`,
                    color: "브랜드 컬러",
                    colorContent: `오래전부터 사용한 우리의 단청 컬러를 활용하여 픽셀 느낌의 아이콘과 함께 서로와 어울리며 구성됩니다.`,
                    title1: result.type2Ko.title1,
                    content1: result.type2Ko.content1,
                    color1: result.type2Ko.color1,
                    tag1: result.type2Ko.tag1,
                    title2: result.type2Ko.title2,
                    content2: result.type2Ko.content2,
                    color2: result.type2Ko.color2,
                    tag2: result.type2Ko.tag2,
                    art: "그래픽 아트워크",
                });

                setType2En({
                    brand: `Brand Character`,
                    content: `We worked on applying variations to the Grafflex character BOLD, to allow for various exposures.`,
                    color: `Brand Color`,
                    colorContent: `It is composed by utilizing our Dancheong colors that have been used for a long time and harmonizing with pixel-like icons.`,
                    title1: result.type2En.title1,
                    content1: result.type2En.content1,
                    color1: result.type2En.color1,
                    tag1: result.type2En.tag1,
                    title2: result.type2En.title2,
                    content2: result.type2En.content2,
                    color2: result.type2En.color2,
                    tag2: result.type2En.tag2,
                    art: "Graphic Artwork",
                });
                setContentKo(result.contentKo);
                setContentEn(result.contentEn);

                setPreviewUrl(`${APIURL}/${result.imagePath}`);
                const imagePaths = JSON.parse(result.imagePaths);
                const imageUrls = imagePaths.map((path: any) => `${APIURL}/${path.path}`);
                setPreviews(imageUrls);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, []);

    const [top, setTop] = useState(window.innerWidth <= 768 ? 24 : 0);

    useEffect(() => {
        const handleResize = () => {
            setTop(window.innerWidth <= 768 ? 24 : 0);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // const handleScroll = (): void => {
    //     if (!imgRef.current) return;

    //     const position: number = window.pageYOffset;
    //     const isPc: boolean = window.innerWidth > 820;

    //     // console.log(position);

    //     // PC에서는 스크롤이 500 이상, 모바일에서는 700 이상일 때 애니메이션 실행
    //     if ((isPc && position >= 760) || (!isPc && position >= 870)) {
    //         setAnimateImg(true);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-BI`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-브랜드`}</title>
                </Helmet>
            )}
            <Navibar />
            <WrapBlack>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? titleEn : titleKo}</Title>
                    <DividerWhite />
                    <FlexContainer>
                        <SubTitleWhite>{activeLanguage === "en" ? type1En.brand : type1Ko.brand}</SubTitleWhite>
                        <DescriptionWrapperWhiteTop>
                            <div className="box">
                                <div className="title">{activeLanguage === "en" ? type1En.title : type1Ko.title}</div>
                                <div className="desc1">
                                    {activeLanguage === "en" ? type1En.content : type1Ko.content}
                                </div>
                            </div>
                            <BiImage alignItems={"flex-start"} top={top}>
                                <img className="bi" src={seoulImage} alt="bi1" />
                            </BiImage>
                        </DescriptionWrapperWhiteTop>
                    </FlexContainer>
                    <DividerWhite />
                    <FlexContainer>
                        <SubTitleWhite>{activeLanguage === "en" ? type2En.color : type2Ko.color}</SubTitleWhite>
                        <DescriptionWrapperWhite>
                            <div className="title">{activeLanguage === "en" ? type2En.title1 : type2Ko.title1}</div>
                            <div className="desc1">{activeLanguage === "en" ? type2En.content1 : type2Ko.content1}</div>
                            <div className="title margin">
                                {activeLanguage === "en" ? type2En.title2 : type2Ko.title2}
                            </div>
                            <div className="desc1">{activeLanguage === "en" ? type2En.content2 : type2Ko.content2}</div>
                            <ColorBoxWhite>
                                <ColorInnerWhite bgColor="#000000">
                                    <div className="box"></div>
                                    <div className="fontbox">
                                        <div className="font">#000000</div>
                                        <div className="tag">
                                            {activeLanguage === "en" ? type2En.tag1 : type2Ko.tag1}
                                        </div>
                                    </div>
                                </ColorInnerWhite>
                                <ColorInnerWhite bgColor="#ff0e14">
                                    <div className="box red"></div>
                                    <div className="fontbox">
                                        <div className="font">#ff0e14</div>
                                        <div className="tag">
                                            {activeLanguage === "en" ? type2En.tag2 : type2Ko.tag2}
                                        </div>
                                    </div>
                                </ColorInnerWhite>
                            </ColorBoxWhite>
                        </DescriptionWrapperWhite>
                    </FlexContainer>
                </PaddingWrap>
            </WrapBlack>
            <Wrap>
                <PaddingWrapBlack>
                    <Divider />
                    <FlexContainer>
                        <SubTitle>{activeLanguage === "en" ? type1En.newBrand : type1Ko.newBrand}</SubTitle>
                        <DescriptionWrapper>
                            <div className="title">{activeLanguage === "en" ? type1En.newTitle : type1Ko.newTitle}</div>
                            <div className="desc1">
                                {activeLanguage === "en" ? type1En.newContent : type1Ko.newContent}
                            </div>
                            <BiImage alignItems={"flex-start"} top={36}>
                                <img className="bi1" src={bi1} alt="bi1" />
                            </BiImage>
                            <Author>{activeLanguage === "en" ? type1En.author : type1Ko.author}</Author>
                            <AuthorDesc>{activeLanguage === "en" ? type1En.authorDesc : type1Ko.authorDesc}</AuthorDesc>
                            <AuthorImage>
                                <img className="author" src={author} alt="author" />
                            </AuthorImage>
                        </DescriptionWrapper>
                    </FlexContainer>
                    <Divider />
                    <FlexContainer>
                        <SubTitle>{activeLanguage === "en" ? type2En.brand : type2Ko.brand}</SubTitle>
                        <DescriptionWrapper>
                            <div className="desc2">{activeLanguage === "en" ? type2En.content : type2Ko.content}</div>
                            <BiImage alignItems={"center"} top={36}>
                                <img className="stay" src={charStay} alt="stay" />
                            </BiImage>
                        </DescriptionWrapper>
                    </FlexContainer>
                    <Divider />
                    <FlexContainer>
                        <SubTitle>{activeLanguage === "en" ? type2En.color : type2Ko.color}</SubTitle>
                        <DescriptionWrapper>
                            <div className="desc2">
                                {activeLanguage === "en" ? type2En.colorContent : type2Ko.colorContent}
                            </div>
                            <ColorBox>
                                <ColorInner bgColor="#ffffff">
                                    <div className="box"></div>
                                    <div className="font">#FFFFFF</div>
                                </ColorInner>
                                <ColorInner bgColor="#000000">
                                    <div className="box"></div>
                                    <div className="font">#000000</div>
                                </ColorInner>
                                <ColorInner bgColor="#c30f23">
                                    <div className="box"></div>
                                    <div className="font">#c30f23</div>
                                </ColorInner>
                                <ColorInner bgColor="#006834">
                                    <div className="box"></div>
                                    <div className="font">#006834</div>
                                </ColorInner>
                                <ColorInner bgColor="#066eb7">
                                    <div className="box"></div>
                                    <div className="font">#066eb7</div>
                                </ColorInner>
                                <ColorInner bgColor="#fdd800">
                                    <div className="box"></div>
                                    <div className="font">#fdd800</div>
                                </ColorInner>
                            </ColorBox>
                        </DescriptionWrapper>
                    </FlexContainer>
                    <Divider />
                    <FlexContainer>
                        <SubTitle>{activeLanguage === "en" ? type2En.art : type2Ko.art}</SubTitle>
                        <DescriptionWrapper>
                            <BiImage alignItems={"center"} top={0}>
                                <img className="art" src={artm} alt="art" />
                                <img className="artpc" src={artpc} alt="art" />
                            </BiImage>
                        </DescriptionWrapper>
                        <Bottom />
                    </FlexContainer>
                </PaddingWrapBlack>
            </Wrap>
            <Footer />
        </>
    );
};

export default Bi;

const Wrap = styled(Wraps)`
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
`;

const WrapBlack = styled(Wraps)`
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    background: #000000;
`;
const PaddingWrap = styled(PaddingWraps)`
    flex-direction: column;
    align-items: center;
    @media (min-width: 1440px) {
        max-width: 1200px;
    }
`;
const PaddingWrapBlack = styled(PaddingWrap)`
    margin-top: 44px;
`;

const Title = styled(Titles)`
    color: #ffffff;
`;

const DividerWhite = styled(Dividers)`
    border-bottom: 4px solid #ffffff;
`;

const Divider = styled(Dividers)``;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 1440px) {
        flex-direction: row;
        padding-top: 4px;
    }
`;

const SubTitle = styled.div`
    ${applyTypography("primary", 3)};
    @media (min-width: 1440px) {
        width: 30%;
    }
`;
const SubTitleWhite = styled(SubTitle)`
    color: #ffffff;
`;

const DescriptionWrapper = styled.div`
    margin-top: 28px;
    margin-bottom: 48px;

    @media (min-width: 768px) {
        margin-top: 40px;
        margin-bottom: 84px;
    }
    @media (min-width: 1440px) {
        width: 70%;
        margin-top: 0;
    }
    .title {
        ${applyTypography("primary", 4)};
    }

    .desc1 {
        margin-top: 24px;
        ${applyTypography("secondary", 0)};
        line-height: 25px;
        @media (min-width: 768px) {
            line-height: 28px;
        }
    }
    .desc2 {
        ${applyTypography("secondary", 0)};
        line-height: 25px;

        @media (min-width: 768px) {
            line-height: 28px;
        }
    }
`;
const DescriptionWrapperWhite = styled(DescriptionWrapper)`
    .title {
        color: #ffffff;
    }
    .desc1 {
        color: #ffffff;
    }
    .margin {
        margin-top: 36px;
    }
`;
const DescriptionWrapperWhiteTop = styled(DescriptionWrapperWhite)`
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;

        .box {
            padding-right: 85px;
        }
    }
`;

const BiImage = styled.div<{ alignItems?: string; top?: number }>`
    margin-top: ${({ top }) => top}px;
    display: flex;
    justify-content: flex-start;
    align-items: ${({ alignItems }) => alignItems};
    flex-wrap: wrap;

    .bi {
        width: 120px;
    }

    .bi1 {
        width: 100%;
        @media (min-width: 768px) {
            max-width: 670px;
            height: 298px;
        }
    }
    /* .bi2 {
        width: 49vw;
        @media (min-width: 768px) {
            max-width: 369px;
        }
    } */
    .stay {
        width: 100%;
        object-fit: cover;
        @media (min-width: 768px) {
            max-width: 670px;
            height: 298px;
        }
    }
    /* .run {
        height: 32vw;
        margin-right: 4%;
        @media (min-width: 768px) {
            max-width: 294px;
            height: 247px;
        }
    }
    .smile {
        height: 18vw;
        @media (min-width: 768px) {
            max-width: 125px;
            height: 151px;
        }
    } */
    .art {
        width: 100%;
        margin-bottom: 12px;
        @media (min-width: 768px) {
            display: none;
        }
    }
    .artpc {
        display: none;
        @media (min-width: 768px) {
            display: block;
            width: 100%;
        }
    }
`;
const Author = styled.div`
    margin-top: 70px;
    ${applyTypography("primary", 4)};
`;

const AuthorDesc = styled.div`
    margin-top: 24px;
    ${applyTypography("secondary", 0)};
    line-height: 25px;
`;
const AuthorImage = styled.div`
    margin-top: 36px;
    img {
        width: 100%;
        @media (min-width: 768px) {
            max-width: 700px;
        }
    }
`;
const ColorBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
`;
const ColorBoxWhite = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
        margin-top: 44px;
        flex-direction: row;
    }
`;

const ColorInner = styled.div<{ bgColor?: string }>`
    display: flex;
    align-items: center;
    width: 48%;
    margin-top: 24px;
    @media (min-width: 768px) {
        width: 33%;
    }
    .box {
        width: 48px;
        height: 48px;
        border: 4px #000 solid;
        margin-right: 8%;
        background-color: ${({ bgColor }) => bgColor};
    }
    .font {
        text-transform: uppercase;
        ${applyTypography("secondary", 0)};
    }
`;
const ColorInnerWhite = styled.div<{ bgColor?: string }>`
    display: flex;
    align-items: center;
    @media (min-width: 768px) {
        width: 50%;
    }
    .box {
        width: 48px;
        height: 48px;
        border: 2px #ffffff solid;
        margin-right: 4%;
        background-color: ${({ bgColor }) => bgColor};
    }
    .red {
        border: 2px #ff0e14 solid;
    }
    .fontbox {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .tag {
            ${applyTypography("secondary", 1)};
            color: #ababab;
        }
        .font {
            text-transform: uppercase;
            ${applyTypography("secondary", 0)};
            color: #ffffff;
        }
    }
`;
const Bottom = styled.div`
    margin-bottom: 90px;
`;
// const ContentSection = styled.div`
//     width: 80%;
//     max-width: 1544px;
//     height: auto;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     top: 0px;

//     @media (max-width: 820px) {
//         width: 90%;
//     }
// `;

// const ContentSection2 = styled.div`
//     width: 80%;
//     max-width: 1544px;
//     height: auto;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     top: 80px;

//     @media (max-width: 820px) {
//         width: 90%;
//         height: auto;
//     }
// `;

// const ContentSection3 = styled.div`
//     width: 80%;
//     max-width: 1544px;
//     height: auto;
//     display: flex;
//     position: relative;
//     top: 150px;
//     margin-bottom: 250px;

//     @media (max-width: 820px) {
//         width: 90%;
//     }
// `;

// const Title = styled.h1`
//     font-size: 48px;
//     margin-bottom: 50px;
//     position: absolute;
//     top: -100px;
//     left: 0;
//     font-family: var(--font--bold);

//     @media (max-width: 820px) {
//         top: -100px;
//     }

//     @media (max-width: 550px) {
//         font-size: 26px;
//     }
// `;

// const Divider = styled.hr`
//     width: 100%;
//     background: rgba(58, 58, 58, 1);
//     opacity: 0.3;
//     position: absolute;
//     top: 15px;
//     left: 0;

//     @media (max-width: 550px) {
//         top: -30px;
//     }
// `;

// const Divider2 = styled.hr`
//     width: 100%;
//     background: rgba(58, 58, 58, 1);
//     opacity: 0.3;
//     position: absolute;
//     top: 10px;
//     left: 0;

//     @media (max-width: 820px) {
//         top: 10px;
//     }

//     @media (max-width: 550px) {
//         top: -30px;
//     }
// `;

// const Divider3 = styled.hr`
//     width: 100%;
//     background: rgba(58, 58, 58, 1);
//     opacity: 0.3;
//     position: absolute;
//     top: 10px;
//     left: 0;

//     @media (max-width: 820px) {
//         top: 10px;
//     }

//     @media (max-width: 550px) {
//         top: -30px;
//     }
// `;

// const SubTitle = styled.h5`
//     width: 40%;
//     font-size: 30px;
//     align-items: flex-start;
//     margin-top: 60px;
//     font-family: var(--font--bold);

//     @media (max-width: 550px) {
//         font-size: 18px;
//         margin-top: 15px;
//     }
// `;

// const DescriptionWrapper = styled.div`
//     width: 60%;
//     height: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     margin-top: 60px;

//     .visible {
//         visibility: visible;
//     }

//     .second.animate__animated {
//         animation-delay: 0.3s;
//     }

//     .custom-fadeInUp {
//         animation: fadeInUp 1s ease forwards;
//         transform: translateY(15%);
//     }

//     @keyframes fadeInUp {
//         from {
//             opacity: 0;
//             transform: translateY(15%);
//         }
//         to {
//             opacity: 1;
//             transform: translateY(0);
//         }
//     }

//     img {
//         width: 100%;
//         margin-bottom: 20px;
//     }

//     @media (max-width: 820px) {
//         width: 100%;
//         margin-top: 0;

//         img {
//             max-width: 100%;
//         }
//     }
// `;

// const Description = styled.p`
//     margin-top: 0;
//     font-size: 22px;
//     font-weight: 700;
//     line-height: 32px;
//     font-family: var(--font--bold);

//     @media (max-width: 550px) {
//         font-size: 16px;
//     }
// `;

// const Description2 = styled.p`
//     font-size: 18px;
//     line-height: 30px;
//     margin-top: 0px;
//     white-space: pre-wrap;

//     @media (max-width: 550px) {
//         font-size: 16px;
//         line-height: 25px;
//     }
// `;

// const BrandSymbol = styled.div`
//     width: 100%;
//     align-self: flex-start;
//     margin-top: 30px;
//     max-width: 100%;
//     object-fit: cover;
//     visibility: hidden;

//     .visible {
//         visibility: visible;
//     }

//     animate__animated {
//         animation-delay: 0.1s;
//     }

//     .custom-fadeInUp {
//         animation: fadeInUp 1s ease forwards;
//         transform: translateY(15%);
//     }

//     @keyframes fadeInUp {
//         from {
//             opacity: 0;
//             transform: translateY(15%);
//         }
//         to {
//             opacity: 1;
//             transform: translateY(0);
//         }
//     }

//     @media (max-width: 820px) {
//         max-width: 100%;
//     }
// `;

// const FlexWrap = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     font-size: 18px;
//     position: relative;

//     .brandColor {
//         display: flex;
//         align-items: center;
//         height: auto;

//         .colorText {
//             height: 100%;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;

//             .tag {
//                 margin-top: 10px;
//             }
//         }

//         .colorText p {
//             margin: 0;
//         }

//         .color {
//             min-width: 48px;
//             max-width: 48px;
//             min-height: 48px;
//             max-height: 48px;
//             background: black;
//             border: 2px solid white;
//             margin-right: 20px;
//         }
//         .colorRed {
//             min-width: 48px;
//             max-width: 48px;
//             min-height: 48px;
//             max-height: 48px;
//             background: red;
//             border: 2px solid black;
//             margin-right: 20px;
//         }
//     }

//     .brandColor .colorText p {
//         margin: 0;
//     }

//     .tag {
//         color: rgba(143, 143, 143, 1);
//         font-size: 14px;
//     }

//     @media (max-width: 820px) {
//         width: 100%;
//     }

//     @media (max-width: 550px) {
//         flex-direction: column;

//         .brandColor {
//             width: 100%;
//             margin-bottom: 20px;

//             .colorText {
//                 p:first-child {
//                     font-size: 16px;
//                 }

//                 p:last-child {
//                     font-size: 11.3px;
//                 }
//             }
//         }
//     }
// `;
