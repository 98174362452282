// src/styles/mixins.js
import { css } from "styled-components";
import typography from "./typography";

export const applyTypography = (styleKey, sizeIndex = 0) => {
    const style = typography.fontStyles[styleKey];

    return css`
        font-family: ${style.fontFamily};
        font-size: ${style.fontSizes.web[sizeIndex]};
        font-weight: ${style.fontWeights.medium};
        color: #000;

        @media (max-width: 768px) {
            font-size: ${style.fontSizes.mobile[sizeIndex]};
        }
    `;
};
