import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import square from "../../assets/images/info/img-square.svg";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { fadeInUpAnimation } from "./GuideMap";
import bg1 from "../../assets/images/info/tourist-ko1.jpg";
import bg2 from "../../assets/images/info/tourist-ko2.jpg";
import bg3 from "../../assets/images/info/tourist-en2.jpg";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Titles from "../../components/Title";
import Dividers from "../../components/Dividers";
import { Link } from "react-router-dom";
import { applyTypography } from "../../styles/mixins";

const Tourist = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [isKoHovered, setIsKoHovered] = useState(false);
    const [isEnHovered, setIsEnHovered] = useState(false);

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
    }, []);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Tourist Sights`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-인근 관광지`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? "Tourist Sights" : "인근 관광지"}</Title>
                    <Divider />
                    <Contents>
                        <div className={"box visible animate__animated animate__fadeInUp"}>
                            <img src={bg1} className="bg" />
                            <Center>
                                <div className="text">
                                    {activeLanguage === "en" ? (
                                        <>
                                            {"Feel free to ask me anything"}
                                            <br />
                                            {"about your trip to Seoul"}
                                        </>
                                    ) : (
                                        <>{"서울 여행, AI 챗봇에게 무엇이든 물어보세요"}</>
                                    )}
                                </div>
                                <LinkBtn>
                                    <StyleLink to="https://triple.guide/seoulcon">
                                        {activeLanguage === "en" ? "Travel Chatbot Service" : "AI 챗봇 서비스 바로가기"}
                                    </StyleLink>
                                </LinkBtn>
                            </Center>
                        </div>

                        <div className={"box second visible animate__animated animate__fadeInUp"}>
                            {activeLanguage === "en" ? (
                                <img src={bg3} className="bg" />
                            ) : (
                                <img src={bg2} className="bg" />
                            )}

                            <Center>
                                <div className="text">
                                    {activeLanguage === "en" ? (
                                        <>
                                            {"TRIPLE global is your one"}
                                            <br />
                                            {"and only travel expert"}
                                        </>
                                    ) : (
                                        <>{"나를 아는 여행 앱, 트리플"}</>
                                    )}
                                </div>
                                {activeLanguage === "en" ? (
                                    <LinkBtn>
                                        <StyleLink to="https://triple.global/articles/60cc5ecb-587f-43af-a65b-a78d2dc5e1ea">
                                            About TRIPLE global
                                        </StyleLink>
                                    </LinkBtn>
                                ) : (
                                    <LinkBtn>
                                        <StyleLink to="https://triple.guide">'트리플'이란?</StyleLink>
                                    </LinkBtn>
                                )}
                            </Center>
                        </div>
                    </Contents>
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default Tourist;

const Wrap = styled(Wraps)``;

const PaddingWrap = styled(PaddingWraps)``;

const Title = styled(Titles)``;

const Divider = styled(Dividers)``;

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    .bg {
        width: 100%;
        height: 50vw;
        object-fit: cover;
        border: 4px solid black;
        box-sizing: border-box;

        @media (min-width: 768px) {
            height: 45vw;
            max-height: 500px;
        }
        @media (min-width: 1440px) {
            height: 25vw;
        }
    }

    @media (min-width: 768px) {
        margin-bottom: 120px;
    }
    @media (min-width: 1440px) {
        flex-direction: row;
        gap: 2%;
    }
`;

const Center = styled.div`
    margin-top: 20px;

    @media (min-width: 768px) {
        margin-top: 28px;
    }
    .text {
        ${applyTypography("primary", 3)};
        text-align: center;
        @media (min-width: 768px) {
            text-align: left;
        }
    }
`;
const LinkBtn = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 60px;

    @media (min-width: 768px) {
        justify-content: flex-start;
        margin-top: 40px;
    }
`;
const StyleLink = styled(Link)`
    ${applyTypography("secondary", 3)};
    padding: 18px 70px;
    border: none;
    background-color: black;
    color: #f9f8f8;
    cursor: pointer;

    &:hover {
        background-color: #fed800;
        color: black;
    }
`;
// const Contents = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     margin-bottom: 100px;
//     visibility: hidden;
//     position: relative;
//     z-index: 99;

//     div {
//         ${fadeInUpAnimation}
//     }

//     .box {
//         width: 49.5%;
//         height: 360px;
//         margin-bottom: 20px;
//         position: relative;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         text-align: center;
//         overflow: hidden;

//         &::after {
//             content: "";
//             position: absolute;
//             top: 0;
//             bottom: 0;
//             left: 0;
//             right: 0;
//             height: 100%;
//             pointer-events: none;
//             background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(47, 83, 0, 0.5) 100%);
//             transition: 0.3s;
//             opacity: 0;
//             z-index: 1;
//         }

//         &:hover::after {
//             opacity: 1;
//         }

//         .bg {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//             transition: transform 0.5s, filter 0.3s;
//             will-change: transform, filter;
//             filter: brightness(0.3);
//         }

//         &:hover .bg {
//             filter: none;
//             transform: scale(1.1);
//             transition: 0.5s;
//         }

//         .center {
//             position: absolute;
//             z-index: 2;
//             width: 100%;
//             display: flex;
//             flex-direction: column;

//             b {
//                 display: inline-block;
//                 color: rgba(191, 255, 106, 1);
//                 font-size: 30px;
//                 margin-bottom: 44px;
//                 line-height: 36px;
//                 font-family: var(--font--bold);
//             }
//         }

//         button {
//             width: 320px;
//             height: 52px;
//             border-radius: 50px;
//             background: none;
//             color: white;
//             border: 1px solid white;
//             font-size: 16px;
//             cursor: pointer;
//             transition: background-color 0.3s ease, color 0.3s ease;

//             img {
//                 width: 12px;
//                 height: 12px;
//                 margin-left: 5px;
//             }
//         }

//         .last {
//             width: 257px;
//         }

//         button:hover {
//             background: white;
//             color: black;
//         }

//         button:hover img {
//             content: url(${buttonArrowBlack});
//         }
//     }

//     .visible {
//         visibility: visible;
//     }

//     .second.animate__animated {
//         animation-delay: 0.2s;
//     }

//     @keyframes fadeInUp {
//         from {
//             opacity: 0;
//             transform: translateY(15%);
//         }
//         to {
//             opacity: 1;
//             transform: translateY(0);
//         }
//     }

//     @media (max-width: 900px) {
//         justify-content: center;
//         flex-direction: column;
//         visibility: hidden;

//         .box {
//             width: 100%;
//             pointer-events: none;
//             animation: fadeInUp 1s ease forwards;
//             visibility: visible;

//             button {
//                 pointer-events: auto;
//             }
//         }
//         .custom-fadeInUp {
//             animation: fadeInUp 1s ease forwards;
//             transform: translateY(5%);
//         }

//         @keyframes fadeInUp {
//             from {
//                 opacity: 0;
//                 transform: translateY(5%);
//             }
//             to {
//                 opacity: 1;
//                 transform: translateY(0);
//             }
//         }
//     }

//     @media (max-width: 670px) {
//         .box {
//             margin-top: 0px;
//         }
//     }

//     @media (max-width: 610px) {
//         .box {
//             height: 300px;
//             min-height: 0px;
//             position: relative;
//             margin-top: 0px;
//             margin-bottom: 20px;

//             .conImg {
//                 height: 300px;
//                 object-fit: cover;
//                 display: block;
//             }

//             .center {
//                 div {
//                     width: 80%;
//                     margin: 10px auto;
//                 }

//                 b {
//                     font-size: 18px;
//                     margin-bottom: 10px;
//                     line-height: 28px;
//                 }

//                 button {
//                     width: 300px;
//                     margin-top: 10px;
//                 }

//                 div {
//                     span {
//                         font-size: 16px;
//                         line-height: 25px;
//                     }
//                 }
//             }
//         }
//     }

//     @media (max-width: 480px) {
//         .box {
//             margin-top: 0px;
//         }
//     }
// `;
